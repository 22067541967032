import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import './LocationsStatsModal.scss'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    data?: any
    selectedMonth: string
}
const c = 'locations-stats-modal'

const LocationsMonthStatsModal = (props: Props) => {
  const {t} = useTranslation()

  const formatLocations = () => {
    return Object.keys(props.data).map((location: any) => {
      return {
        name: location,
        quantity: props.data[location]
      }
    })
  }

  const sortedLocations = () => {
    return formatLocations()?.sort((a: any, b: any) => b.quantity - a.quantity)
  }

  return (
    <ReactModal ariaHideApp={false} className='locations-stats-modal-container' isOpen={props.isOpen}>
      <div className={c}>
        <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div>
        <div className={`${c}-title`}>{t(props.selectedMonth?.toLocaleUpperCase())}</div>
        <div className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Localitate</TableCell>
                  <TableCell className='table-header-text'>Nr. de Comenzi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedLocations().length ? sortedLocations().map((location: any, index: number) => (
                  <TableRow key={index} className='table-body-row'>
                    <TableCell>
                      {location.name}
                    </TableCell>
                    <TableCell>
                      {location.quantity}
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell align='center' colSpan={2}>
                      Nu s-au inregistrat comenzi in aceasta luna
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </ReactModal>
  )
}

export default LocationsMonthStatsModal