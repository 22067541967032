import React, { useEffect, useState } from 'react'
import EditOrdersModal from './orders-modal/EditOrdersModal'
import CreateOrderModal from './orders-modal/CreateOrderModal'
import { deleteOrder, getOrders } from '../../../../api/order'
import { IOrder, IOrderResponse } from '../../../../types/orders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Store } from 'react-notifications-component'
import { Button } from '@mui/material'
import DeleteConfirmationModal from './orders-modal/DeleteConfirmationModal'
import './OrdersPage.scss'

const c = 'orders-page'

const OrdersPage = () => {
  const [orders, setOrders] = useState<IOrderResponse[]>([])
  const [openEdit, setOpenEdit] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [order, setOrder] = useState<IOrder>()
  const [openConfirm, setOpenConfirm] = useState(false)

  function getOrdersData() {
    getOrders().then((res) => {
      setOrders(res.data)
    })
  }

  function handleDelete(orderId: string){
    const newOrders = orders.map((order: IOrderResponse) => {
      return {
        date: order.date,
        orders: order.orders.filter((order: IOrder) => order._id !== orderId)
      }
    })

    setOrders(newOrders)

    deleteOrder(orderId)
      .then(() => {
        Store.addNotification({
          title: 'SUCCESS',
          message: 'Success',
          container: 'top-center',
          type: "success",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
      })
      .finally(() => {
        setOpenConfirm(false)
      }) 
  }

  function handleEdit(order: IOrder){
    setOpenEdit(true)
    setOrder(order)
  }

  function openConfirmModal(order: IOrder){
    setOpenConfirm(true)
    setOrder(order)
  }

  useEffect(() => {
    getOrdersData()
  },[])

  return (
    <div className={c}>
      <div className={`${c}__create`}>
        <div className={`${c}__create-btn`}>
          <Button
            variant='contained'
            onClick={() => setOpenCreate(true)}
            sx={{
              backgroundColor: '#ff1647',
              textTransform: 'none',
              fontWeight: 'bold',
              width: '100%',
              '&:hover': { backgroundColor: '#cc1138' },
            }}
          >
            Adauga
          </Button>
        </div>
      </div>
      {orders?.map((item: IOrderResponse, index: number) => {
        return (
          <div key={index} className={`${c}__order`}>
            <div className={`${c}__order-title`}>{item.date == new Date().toLocaleDateString() ? 'Astazi' : item.date}</div>
            <div className={`${c}__order-items`}>
              {item.orders.map((order: IOrder, index: number) => (
                <div key={index} className={`${c}__order-items-item`}>
                  <div className={`${c}__order-items-item-actions`}>
                    <div className={`${c}__order-items-item-actions-action`}>
                      <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(order)} /> 
                    </div>
                    <div className={`${c}__order-items-item-actions-action`}>
                      <FontAwesomeIcon icon={faTrashCan} onClick={() => openConfirmModal(order)} /> 
                    </div>
                  </div>
                  <div className={`${c}__order-items-item-details`}>
                    <div className={`${c}__order-items-item-details-row`}>
                      <span className={`${c}__order-items-item-details-row-bold`}>{order.delivery_method}</span> - <span>{order.location}</span>
                    </div>
                    <div className={`${c}__order-items-item-details-row`}>Ora: {order.time}</div>
                    <div className={`${c}__order-items-item-details-row`}>Nume: {order.name}</div>
                    <div className={`${c}__order-items-item-details-row`}>Telefon: {order.phone}</div>
                    <div className={`${c}__order-items-item-details-row`}>Metoda de plata: {order.payment}</div>
                    <div className={`${c}__order-items-item-details-row`}>Pretul Livrarii: {order.delivery_cost}</div>
                    <div className={`${c}__order-items-item-details-row`}>Pretul Comenzii: {order.order_price}</div>
                    <div className={`${c}__order-items-item-details-row`}>Detalii: {order.details}</div>
                    <div className={`${c}__order-items-item-details-row`}><span className={`${c}__order-items-item-details-row-bold`}>TOTAL: {order.total_price}</span></div>
                  </div>
                  <div className={`${c}__order-items-item-cart`}>
                    <div className={`${c}__order-items-item-cart-title`}>Comanda:</div>
                    {order.cart.map((product: any, index: number) => (
                      <div key={index} className={`${c}__order-items-item-cart-item`}>
                        <div className={`${c}__order-items-item-cart-item-name`}>{product.name} x {product.quantity}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )})}
      <EditOrdersModal isOpen={openEdit} setIsOpen={setOpenEdit} refetch={getOrdersData} data={order} />
      <CreateOrderModal isOpen={openCreate} setIsOpen={setOpenCreate} refetch={getOrdersData}/>
      <DeleteConfirmationModal isOpen={openConfirm} setIsOpen={setOpenConfirm} handleDelete={handleDelete} order={order} />
    </div>
  )
}

export default OrdersPage