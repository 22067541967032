import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Store } from 'react-notifications-component'
import { deleteIngredient, getIngredients } from '../../../../api/ingredients'
import IngredientsModal from './ingredients-modal/IngredientsModal'
import './IngredientsPage.scss'

const c = 'ingredients-page'

const IngredientsPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [ingredient, setIngredient] = useState<any>()
  const [ingredients, setIngredients] = useState<any[]>([])
  
  function getIngredientsData() {
    getIngredients()
      .then((res) => {
        setIngredients(res?.data)
      })
      .catch((err) => console.log(err))
  }

  function handleDelete(ingredientId: string){
    setIngredients((prev: any) => prev.filter((ingredient: any) => ingredientId !== ingredient._id))

    deleteIngredient(ingredientId).then(() => {
      Store.addNotification({
        title: 'SUCCESS',
        message: 'Sters cu succes!',
        container: 'top-center',
        type: "success",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    })
  }

  function handleEdit(ingredient: any){
    setIsOpenEdit(true)
    setIngredient(ingredient)
  }
  
  useEffect(() => {
    getIngredientsData()
  }, [])
  
  return (
    <div className={c}>
      <div className={`${c}__add_product`}>
        <div className={`${c}__add_product-btn`}>
          <Button
            variant='contained'
            onClick={() => setIsOpen(true)}
            sx={{
              backgroundColor: '#ff1647',
              textTransform: 'none',
              fontWeight: 'bold',
              width: '100%',
              '&:hover': { backgroundColor: '#cc1138' },
            }}
          >
            Adauga
          </Button>
        </div>
      </div>
      <div className={`${c}__ingredients`}>
        {ingredients?.map((ingredient) => (
          <div key={ingredient._id} className={`${c}__ingredients-item`}>
            <div className={`${c}__ingredients-item-title`}>{ingredient.label}</div>
            <div className={`${c}__ingredients-item_prices`}>
              <div className={`${c}__ingredients-item-prices-price`}>{ingredient.price_gram} lei (gram)</div>
              <div className={`${c}__ingredients-item-prices-price`}>{ingredient.price_piece} lei (bucata)</div>
            </div>
            <div className={`${c}__ingredients-item-actions`}>
              <div className={`${c}__ingredients-item-actions-btn`}> 
                <Button
                  variant='contained'
                  onClick={() => handleEdit(ingredient)}
                  sx={{
                    backgroundColor: '#ff1647',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    width: 'fit-content',
                    '&:hover': { backgroundColor: '#cc1138' },
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </div>
              <div className={`${c}__ingredients-item-actions-btn`}> 
                <Button
                  variant='contained'
                  onClick={() => handleDelete(ingredient._id)}
                  sx={{
                    backgroundColor: '#ff1647',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    width: 'fit-content',
                    '&:hover': { backgroundColor: '#cc1138' },
                  }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <IngredientsModal isOpen={isOpen} setIsOpen={setIsOpen} refetch={getIngredientsData} />
      <IngredientsModal isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} data={ingredient} refetch={getIngredientsData} />
    </div>
  )
}

export default IngredientsPage