import React, { useState } from 'react'
import { faBars, faCartShopping, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { styled, alpha } from '@mui/material/styles';
import { InputBase } from '@mui/material'
import LanguagesDropdown from '../../languages-dropdown/LanguagesDropdown'
import Logo from '../../../assets/images/logo_text.png'
import './NavbarMobile.scss'

type Props = {
    cart: any;
    setIsOpen:(isOpen: boolean) => void
    searchValue?: string
    setSearchValue?: (value: string) => void
}


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: '0',
      '&:focus': {
        width: '8ch',
      },
    },
  },
}));

const c = 'main-navbar-mobile'

const NavbarMobile = (props: Props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [scrollTimeout, setScrollTimeout] = useState<any>(null);
  const generalInfo = JSON.parse(localStorage.getItem('info') || '')

  function handleChange(e: any){
    props.setSearchValue?.(e.target.value);

    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    const timeout = setTimeout(() => {
      window.scrollTo({ top: window.innerHeight - 300, behavior: 'smooth' });
    }, 500);

    setScrollTimeout(timeout);
  }

  return (
    <nav className={c}>
      <div className={`${c}__container`}>
        <div className={`${c}__container-nav-left`}>
          <div className={`${c}__container-nav-left-container`}>
            <FontAwesomeIcon icon={faBars} onClick={() => props.setIsOpen(true)} />
          </div>
          <Search>
            <SearchIconWrapper>
              <FontAwesomeIcon icon={faSearch} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={t('SEARCH')}
              value={props.searchValue}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </div>
        
        <div className={`${c}__container-nav-center`}>
          <img src={Logo} onClick={() => navigate('/')}/> 
          {generalInfo.minTime} - {generalInfo.maxTime}
        </div>
        <div className={`${c}__container-nav-right`}>
          <div className={`${c}__container-nav-right-language`}>
            <LanguagesDropdown />
          </div>
          <div className={`${c}__container-nav-right-cart`} onClick={() => navigate('/cart')}>
            <div className={`${c}__container-nav-right-cart-quantity`}>
              {props.cart.reduce((total:number, item:any) => total + item.quantity ,0)}
            </div>
            <FontAwesomeIcon icon={faCartShopping} />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavbarMobile
