import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhone,
  faShareNodes,
} from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './Footer.scss'
import { INSTAGRAM_LINK } from '../../utils/constants'

type Props = {}

const c = 'footer'

const Footer = (props: Props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const generalInfo = JSON.parse(localStorage.getItem('info') || '')

  return (
    <div className={c}>
      {/* <div className={`${c}__background`} /> */}
      <div className={`${c}__content`}>
        <div className={`${c}__content-contact`}>
          <div className={`${c}__content-contact-item`}>
            <div className={`${c}__content-contact-item-icon`}>
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className={`${c}__content-contact-item-info`}>
              <div className={`${c}__content-contact-item-info-label`}>
                {t('CONTACT_US')}
              </div>
              <div className={`${c}__content-contact-item-info-value`}>
                {generalInfo?.phone1} | {generalInfo?.phone2}
              </div>
            </div>
          </div>
          {/* <div className={`${c}__content-contact-item`}>
            <div className={`${c}__content-contact-item-icon`}>
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className={`${c}__content-contact-item-info`}>
              <div className={`${c}__content-contact-item-info-label`}>
                Email-ul nostru
              </div>
              <div className={`${c}__content-contact-item-info-value`}>
                ahmedv3319@gmail.com
              </div>
            </div>
          </div> */}
          <div className={`${c}__content-contact-item`}>
            <div className={`${c}__content-contact-item-icon`}>
              <FontAwesomeIcon icon={faShareNodes} />
            </div>
            <div className={`${c}__content-contact-item-info`}>
              <div className={`${c}__content-contact-item-info-label`}>
                {t('SOCIALS')}
              </div>
              <div className={`${c}__content-contact-item-info-value`}>
                <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer" >
                  <InstagramIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={`${c}__content-list`}>
          <div className={`${c}__content-list-title`}>{t('COMPANY')}</div>
          <div className={`${c}__content-list-divider`} />
          <div className={`${c}__content-list-item`} onClick={() => navigate('/delivery-conditions')}>{t('DELIVERY_CONDITIONS')}</div>
        </div>
      </div>
    </div>
  )
}

export default Footer
