import React, { useEffect } from 'react'
import { faClose} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactModal from 'react-modal'
import { Button, FormHelperText, Grid, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Store } from 'react-notifications-component'
import { createIngredient, editIngredient } from '../../../../../api/ingredients'
import './IngredientsModal.scss'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    data?: any
    refetch?: any
}
const c = 'ingredients-modal'

const IngredientsModal = (props: Props) => {
  const {t} = useTranslation()
  const {register, getValues, handleSubmit, setValue, formState: {errors}} = useForm()

  function submit(){
    const params = getValues()

    if(props.data){
      editIngredient(params, props.data?._id).then(() => {
        Store.addNotification({
          title: 'SUCCESS',
          message: 'Success',
          container: 'top-center',
          type: "success",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
        props.refetch?.()
      }).catch(() => {
        Store.addNotification({
          title: 'ERROR',
          message: 'Error',
          container: 'top-center',
          type: "danger",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
      }).finally(()=>{
        props.setIsOpen(false)
      })
    } else {
      createIngredient(params).then(() => {
        Store.addNotification({
          title: 'SUCCESS',
          message: 'Success',
          container: 'top-center',
          type: "success",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
        props.refetch?.()
      }).catch(() => {
        Store.addNotification({
          title: 'ERROR',
          message: 'Error',
          container: 'top-center',
          type: "danger",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
      }).finally(()=>{
        props.setIsOpen(false)
      })
    }

  }

  useEffect(()=>{
    if(props.data){
      setValue('label', props.data.label)
      setValue('label_ru', props.data?.label_ru)
      setValue('price_gram', props.data.price_gram)
      setValue('price_piece', props.data.price_piece)
    }
  },[props.data])

  return (
    <ReactModal className='ingredients-modal-container' isOpen={props.isOpen}>
      <div className={c}>
        <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div>
        <div className={`${c}-title`}>Ingredient</div>
        <form onSubmit={handleSubmit(submit)} className={`${c}__content`}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <TextField
                {...register('label', {required: true})}
                label='Nume'
                error={!!errors.label}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
              {errors?.label && <FormHelperText error={!!errors?.label} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                {...register('price_gram', {required: true})}
                label='Pret (gram)'
                error={!!errors.delivery_price}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
              {errors?.delivery_price && <FormHelperText error={!!errors?.delivery_price} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                {...register('price_piece', {required: true})}
                label='Pret (bucata)'
                error={!!errors.delivery_price}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
              {errors?.delivery_price && <FormHelperText error={!!errors?.delivery_price} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('label_ru', {required: true})}
                label='Nume (ru)'
                error={!!errors.label_ru}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
              {errors?.label_ru && <FormHelperText error={!!errors?.label_ru} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
          </Grid>
          <div className={`${c}__content-submit`}>
            <Button
              variant='contained'
              type='submit'
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                width: '100%',
                '&:hover': { backgroundColor: '#cc1138' },
              }}
            >
            Salveaza
            </Button>
          </div>
        </form>
      </div>
    </ReactModal>
  )
}

export default IngredientsModal