import React, { useEffect, useState } from 'react'
import { IProduct } from '../../../../types/products'
import QuantityInput from '../../../../components/quantity-input/QuantityInput'
import { useTranslation } from 'react-i18next'
import { serverUrl } from '../../../../request'
import './CartPageItemMobile.scss'
import i18next from 'i18next'

type Props = {
  data?: any
  setCart: any
}
const c = 'cart-page-item-mobile'

const CartPageItemMobile = ({ data, setCart }: Props) => {
  const cartData = JSON.parse(localStorage.getItem('cart') || '')
  const [quantity, setQuantity] = useState(data?.quantity || 1)
  const {t} = useTranslation()
  const splitDescription = data?.description.split('_')
  const splitDescriptionRu = data?.ru_description.split('_')

  useEffect(() => {
    setQuantity(data?.quantity)
  }, [data?.quantity])

  useEffect(() => {
    const newCart = [...cartData]

    const cartItemIndex = newCart.findIndex(
      (item: IProduct) => item._id === data._id
    )

    if (cartItemIndex !== -1) {
      newCart[cartItemIndex].quantity = quantity

      if (quantity < 1 ) {
        newCart.splice(cartItemIndex, 1)
      }
    }

    setCart(newCart)
  }, [quantity])

  return (
    <div className={c}>
      <div className={`${c}__info`}>
        <div className={`${c}__info-content`}>
          <div className={`${c}__info-content-image`}>
            <div className={`${c}__info-content-image-props`}>
              {data?.isNew && <div className={`${c}__info-content-image-props-prop`}>{t('NEW')}</div>}
              {data?.discount && <div className={`${c}__info-content-image-props-prop`}>-{data.discount}%</div>}
            </div>
            <img 
              src={serverUrl + data?.image} 
              alt='item' 
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;

                currentTarget.src = '/logo_bg.png';
              }}
            />
          </div>
          <div className={`${c}__info-content-text`}>
            <div className={`${c}__info-content-text-content`}>
              <div className={`${c}__info-content-text-content-title`}>{(i18next.language === 'ru' && data?.ru_name) ? data?.ru_name : data?.name}</div>
              <div className={`${c}__info-content-text-content-description`}>{(i18next.language === 'ru' && data?.ru_description) ? splitDescriptionRu?.map((item: string, index: number) => <div key={index}>{item.trim()}</div>) : splitDescription?.map((item: string, index: number) => <div key={index}>{item.trim()}</div>)}</div>
              <div className={`${c}__info-actions-weight`}>
                {data?.weight ? `${data?.weight}g` : ''} {data?.pieces ? ` - ${data?.pieces} ${t('PIECES')}` : ''}
              </div>
            </div>
          </div>
        </div>
        <div className={`${c}__info-actions`}>
          <div className={`${c}__info-actions-default-price`}>  
            {data.price} lei
          </div>

          <div className={`${c}__info-actions-default`}>
            <div className={`${c}__info-actions-default-quantity`}>
              <QuantityInput
                quantity={quantity}
                setQuantity={setQuantity}
                cartItem
              />
            </div>
            <div className={`${c}__info-actions-total_price`}>
              {t('TOTAL')}: {data?.price * data?.quantity} lei
            </div>
          </div>
        
        </div>
      </div>
    </div>
  )
}

export default CartPageItemMobile
