import { request } from '../request'

export const getCategories = () => {
  return request.get('/categories')
}

export const getCategoryById = (id: string) => {
  return request.get(`/categories/${id}`)
}

export const createCategory = (params: any) => {
  return request.post('/categories', {...params})
}

export const deleteCategory = (id: string) => {
  return request.delete(`/categories/${id}`)
}

export const editCategory = (params: any, id: string) => {
  return request.put(`/categories/${id}`, {...params})
}