import React, { useEffect, useState } from 'react'
import { deleteCategory, editCategory, getCategories } from '../../../../api/categories'
import { ICategory } from '../../../../types/categories'
import { Button } from '@mui/material'
import CategoriesModal from './categories-modal/CategoriesModal'
import './CategoriesPage.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Store } from 'react-notifications-component'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import DropComponent from './DropComponent'

const c = 'categories-page'

const CategoriesPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [category, setCategory] = useState<ICategory>()
  const [categories, setCategories] = useState<ICategory[]>([])
  
  function getCategoriesData() {
    getCategories()
      .then((res) => {
        setCategories(res?.data)
      })
      .catch((err) => console.log(err))
  }

  function handleDelete(categoryId: string){
    setCategories((prev: ICategory[]) => prev.filter((category: ICategory) => categoryId !== category._id))

    deleteCategory(categoryId).then(() => {
      Store.addNotification({
        title: 'SUCCESS',
        message: 'Sters cu succes!',
        container: 'top-center',
        type: "success",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    })
  }

  function handleEdit(category: ICategory){
    setIsOpenEdit(true)
    setCategory(category)
  }
  
  useEffect(() => {
    getCategoriesData()
  }, [])

  function handleOnDragEnd(result: any) {
    if (!result.destination) {
      return;
    }
  
    const updatedCategories = Array.from(categories);
    const [reorderedItem] = updatedCategories.splice(result.source.index, 1);

    updatedCategories.splice(result.destination.index, 0, reorderedItem);
  
    updatedCategories.forEach((category, index) => {
      editCategory({...category, position: index }, category._id);
    });
  
    setCategories(updatedCategories);
  }
  
  return (
    <div className={c}>
      <div className={`${c}__add_product`}>
        <div className={`${c}__add_product-btn`}>
          <Button
            variant='contained'
            onClick={() => setIsOpen(true)}
            sx={{
              backgroundColor: '#ff1647',
              textTransform: 'none',
              fontWeight: 'bold',
              width: '100%',
              '&:hover': { backgroundColor: '#cc1138' },
            }}
          >
            Adauga
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <DropComponent categories={categories} handleEdit={handleEdit} handleDelete={handleDelete}/>
      </DragDropContext>
      <CategoriesModal isOpen={isOpen} setIsOpen={setIsOpen} categories={categories} refetch={getCategoriesData} />
      <CategoriesModal isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} data={category} categories={categories} refetch={getCategoriesData} />
    </div>
  )
}

export default CategoriesPage