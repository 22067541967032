import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import './BannerUpload.scss'
import { deleteImage } from '../../../../../api/general-info';
import { serverUrl } from '../../../../../request';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

type Props = {
files: any
setFiles: any
setImages: any
images: Array<string>
}

const c = 'banner-upload'

const BannerUpload = ({files, setFiles, images, setImages}: Props) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    maxFiles: 100,
    onDrop: (acceptedFiles: any) => {
      setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      setImages(acceptedFiles.map((file: any) => URL.createObjectURL(file)))
    },
  });

  function handleDeleteImage(url: string){
    deleteImage(url.replace(serverUrl, ''))
    setImages((prev: string[]) => {
      return prev.filter((image: string) => image !== url)
    })
  }

  useEffect(() => {
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className={c}>
      <div {...getRootProps()} className={`${c}__dropzone`}>
        <input {...getInputProps()} />
        <p>Incarca una sau mai multe imagini</p>
      </div>
      <aside className={`${c}__previews`}>
        {images.map((image: any) => (
          <div key={image} className={`${c}__previews-item`}>
            <div className={`${c}__previews-item-image`}>
              <img
                src={image}
                onLoad={() => { URL.revokeObjectURL(image) }}
              />
              <div className={`${c}__previews-item-image-close`} onClick={() => handleDeleteImage(image)}>
                <FontAwesomeIcon icon={faClose} />
              </div>
            </div>
          </div>
        ))}
      </aside>
    </section>
  );
}

export default BannerUpload