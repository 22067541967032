import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'

type ICustomInputProps = {
  withAdornment?: boolean
  backgroundColor?: string
  isDatePicker?: boolean
  control?: any
} & TextFieldProps

export const CustomInput = styled(
  ({ isDatePicker, withAdornment, backgroundColor, ...props }: ICustomInputProps) => {

    return (
      <TextField
        InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
        {...props}
      />
    )
  }
)(({ theme, backgroundColor, ...props }) => ({
  '& label.Mui-focused': {
    color: 'black',
  },
  '.MuiFilledInput-root.Mui-error': {
    boxShadow: '#ff1647 0 0 0 2px !important',
  },
  '& label': {
    marginLeft: 0,
    fontSize: '12px'
  },
  '& .MuiFilledInput-input': {
    fontSize: '12px',
    backgroundColor: '#fff',
    '&:focus': {
      backgroundColor: '#fff'
    }

  },
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: backgroundColor ? backgroundColor : '#fff',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    boxShadow: '#000000 0 0 0 1px',

    '&::before': {
      borderBottom: 'none'
    },
    '&::after': {
      borderBottom: 'none'
    },
    '&:hover': {
      background: '#fff !important',
      '&:before': {
        borderBottom: 'none !important'
      }
    },
    '&.Mui-focused': {
      boxShadow: '#ff1647 0 0 0 2px',
      background: '#fff'
    }
  }
}))
