import React from 'react'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, FormHelperText, Grid, TextField } from '@mui/material'
import ReactModal from 'react-modal'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { login } from '../../api/auth'
import { useNavigate } from 'react-router-dom'
import { Store } from 'react-notifications-component'
import './LoginModal.scss'

type Props = {
    isOpen: boolean;
    setIsOpen?: (isOpen: boolean) => void
}
const c = 'login-modal'

const LoginModal = (props: Props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {register, handleSubmit, getValues, formState: {errors}} = useForm()

  function submit(){
    login(getValues())
      .then((res) => {
        localStorage.setItem('token', res.data?.token)
        navigate('/admin/general')
      }).catch((err)=>{
        Store.addNotification({
          title: 'ERROR',
          message: err.message,
          container: 'top-center',
          type: "danger",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
      })
  }

  return (
    <ReactModal shouldCloseOnOverlayClick onRequestClose={() => props.setIsOpen?.(false)} className='login-modal-container' isOpen={props.isOpen}>
      <div className={c}>
        {/* <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div> */}
        <div className={`${c}-title`}>Login</div>
        <form onSubmit={handleSubmit(submit)} className={`${c}__content`}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...register('username', {required: true})}
                label='Username'
                error={!!errors.username}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
              {errors?.username && <FormHelperText error={!!errors?.username} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('password', {required: true})}
                label='Password'
                type='password'
                error={!!errors.password}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
              {errors?.password && <FormHelperText error={!!errors?.password} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
          </Grid>
          <div className={`${c}__content-submit`}>
            <Button
              variant='contained'
              type='submit'
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                width: '100%',
                '&:hover': { backgroundColor: '#cc1138' },
              }}
            >
            Salveaza
            </Button>
          </div>
        </form>
      </div>
    </ReactModal>
  )
}

export default LoginModal