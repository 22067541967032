import React from 'react'
import { useNavigate } from 'react-router-dom'
import './AdminSidebar.scss'

type Props = {}

const c = 'admin-sidebar'

const links = [
  {
    id: 0,
    title: 'General',
    link: 'general'
  },
  {
    id: 1,
    title: 'Produse',
    link: 'products'
  },
  {
    id: 2,
    title: 'Categorii',
    link: 'categories'
  },
  {
    id: 3,
    title: 'Locatii',
    link: 'locations'
  },
  {
    id: 4,
    title: 'Ingrediente',
    link: 'ingredients'
  },
  {
    id: 5,
    title: 'Costuri produse',
    link: 'costs'
  },
  {
    id: 6,
    title: 'Comenzi',
    link: 'orders'
  },
  {
    id: 7,
    title: 'Statistici',
    link: 'statistics'
  },
]

const AdminSidebar = (props: Props) => {
  const navigate = useNavigate()

  return (
    <nav className={c}>
      <div className={`${c}__container`}>
        <div className={`${c}__container-menu`}>
          {links.map((link) => (
            <div key={link.id} className={`${c}__container-menu-item ${window.location.pathname.includes(link.link) ? 'active' : ''}`} onClick={() => navigate(link.link)}>
              {link.title}
            </div>
          ))}
        </div>
      </div>
    </nav>
  )
}

export default AdminSidebar
