import { request } from '../request'

export const getLocations = () => {
  return request.get('/locations')
}

export const createLocation = (params: any) => {
  return request.post('/locations', {...params})
}

export const deleteLocation = (id: string) => {
  return request.delete(`/locations/${id}`)
}

export const editLocation = (params: any, id: string) => {
  return request.put(`/locations/${id}`, {...params})
}