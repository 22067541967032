import { request } from '../request'
import { IHttpResponse } from '../types/general'
import { IOrderResponse } from '../types/orders'

export const checkout = (data: any) => {
  return request.post('/order/send-message', { ...data })
}

export const getOrders = (): Promise<IHttpResponse<IOrderResponse[]>> => {
  return request.get('/order/orders')
}

export const deleteOrder = (orderId: string) => {
  return request.delete(`/order/orders/${orderId}`)
}

export const editOrder = (params: any, orderId: string) => {
  return request.put(`/order/orders/${orderId}`, params)
}

export const createOrder = (params: any) => {
  return request.post(`/order/orders`, params)
}
