import React, { useEffect, useState } from 'react'
import { Button, InputBase } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'
import { getProducts } from '../../api/products'
import { styled, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import './ProductsCostLayout.scss'
import { Outlet, useNavigate } from 'react-router-dom'

const c = 'products-cost-layout'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  marginLeft: 0,
  width: '100%',
  boxShadow: '1px 0px 12px -5px gray',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '10ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const ProductsCostLayout = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [product, setProduct] = useState<any>()
  const [totalPrice, setTotalPrice] = useState({id: '', total_cost: ''})
  const [products, setProducts] = useState<any[]>([])
  const [filteredProducts, setFilteredProducts] = useState<any[]>(products)
  const [searchValue, setSearchValue] = useState('')

  function getProductsData() {
    getProducts()
      .then((res) => {
        setProducts(res?.data)
      })
      .catch((err) => console.log(err))
  }

  function handleChange(e: any){
    setSearchValue(e.target.value);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    setFilteredProducts(() => {
      if (searchValue === '') {
        return products;
      } else {
        return products.map((product: any) => {
          const updatedItems = product.items?.filter(
            (item: any) =>
              (item.name && item.name?.toLowerCase().includes(searchValue.toLowerCase()))
              ||
              (item.ru_name && item.ru_name?.toLowerCase().includes(searchValue.toLowerCase()))
          );

          return {
            ...product,
            items: updatedItems,
          };
        });
      }
    });
  },[searchValue, products])

  function handleEdit(item: any){
    setProduct(item)
    navigate(item._id)
  }
  
  useEffect(() => {
    getProductsData()
  }, [])
  
  return (
    <div className={c}>
      <div className={`${c}__container`}>
        <div className={`${c}__container__header`}>
          <div className={`${c}__container__header-search`}>
            <Search>
              <SearchIconWrapper>
                <FontAwesomeIcon icon={faSearch} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={t('SEARCH')}
                value={searchValue}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </div>
        </div>
        <div className={`${c}__container__products-cost`}>
          {!!filteredProducts.length && filteredProducts?.map((product) => {
            if(!product.items.length) {return null}

            return (
              <div key={product.category_id} className={`${c}__container__products-cost-container`}>
                <div className={`${c}__container__products-cost-container-category`}>{product.category_name}</div>
                <div className={`${c}__container__products-cost-container-items`}>
                  {product.items.map((item: any) => {
                    return <div key={item._id} className={`${c}__container__products-cost-container-items-item`}>
                      <div className={`${c}__container__products-cost-container-items-item-title`}>{item.name}</div>
                      <div className={`${c}__container__products-cost-container-items-item-actions`}>
                        <div className={`${c}__container__products-cost-container-items-item-cost`}>Cost: {totalPrice?.id == item._id ? `${Number(totalPrice?.total_cost).toFixed(2)} lei` : item.total_cost ? `${Number(item.total_cost).toFixed(2)} lei` : '-'}</div>
                        <div className={`${c}__container__products-cost-container-items-item-actions-btn`}> 
                          <Button
                            variant='contained'
                            onClick={() => handleEdit(item)}
                            sx={{
                              backgroundColor: '#ff1647',
                              textTransform: 'none',
                              fontWeight: 'bold',
                              width: 'fit-content',
                              '&:hover': { backgroundColor: '#cc1138' },
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </div>
                      </div>
                    </div>
                  })}
                </div>
              </div>
            )})}
        </div>
      </div>
      <Outlet context={{products, setTotalPrice}} />
    </div>
  )
}

export default ProductsCostLayout