import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getProductById } from '../../../api/products'
import { IProduct } from '../../../types/products'
import { Button } from '@mui/material'
import QuantityInput from '../../../components/quantity-input/QuantityInput'
import { ReactComponent as Loading } from '../../../assets/icons/loading.svg'
import { useTranslation } from 'react-i18next'
import { serverUrl } from '../../../request'
import i18next from 'i18next'
import './ProductViewMobile.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

type Props = {
    setCart: any
    cart: any
}

const c = 'product-view-mobile'

const ProductViewMobile = (props: Props) => {
  const [data, setData] = useState<IProduct>()
  const [loading, setLoading] = useState<boolean>()
  const [quantity, setQuantity] = useState(1)
  const {id} = useParams()
  const {t} = useTranslation()
  const splitDescription = data?.description.split('_')
  const splitDescriptionRu = data?.ru_description.split('_')

  function addToCart() {
    const params = {
      ...data,
      quantity,
      price: data?.discount ? Math.round(Number(data?.price) - (Number(data?.price) * Number(data.discount)) / 100) : data?.price,
    }

    const itemIndex = props.cart.findIndex(
      (cartItem: IProduct) => cartItem._id === data?._id
    )

    if (itemIndex === -1) {
      props.setCart([...props.cart, params])
      localStorage.setItem('cart', JSON.stringify([...props.cart, params]))
    } else {
      const updatedCart = [...props.cart]

      updatedCart[itemIndex].quantity += quantity
      localStorage.setItem('cart', JSON.stringify(updatedCart))
      props.setCart(updatedCart)
    }
  }

  function fetchProductById(){
    setLoading(true)
    getProductById(String(id)).then((res)=>{
      setData(res.data)
    }).finally(() => setLoading(false))
  }

  useEffect(()=>{
    fetchProductById()
  },[])

  return (
    <div className={c}>
      <div className={`${c}__card`}>
        {loading ? <Loading /> : (
          <React.Fragment>
            <div className={`${c}__card-image`}>
              <div className={`${c}__card-image-props`}>
                {data?.isNew && <div className={`${c}__card-image-props-prop`}>{t('NEW')}</div>}
                {data?.discount && <div className={`${c}__card-image-props-prop`}>-{data.discount}%</div>}
              </div>
              <img 
                src={serverUrl + data?.image} 
                alt="" 
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;

                  currentTarget.src = '/logo_bg.png';
                }}
              />
            </div>
            <div className={`${c}__card-content`}>
              <div className={`${c}__card-content-info`}>
                <div className={`${c}__card-content-info-title`}>{(i18next.language === 'ru' && data?.ru_name) ? data?.ru_name : data?.name}</div>
                <div className={`${c}__card-content-info-description`}>{(i18next.language === 'ru' && data?.ru_description) ? splitDescriptionRu?.map((item, index: number) => <div key={index}>{item.trim()}</div>) : splitDescription?.map((item, index: number) => <div key={index}>{item.trim()}</div>)}</div>
              </div>
              <div className={`${c}__card-content-cart`}>
                <div className={`${c}__card-content-cart-weight`}>{data?.weight ? `${data?.weight}g` : ''} {data?.pieces ? ` - ${data?.pieces} ${t('PIECES')}` : ''}</div>
                <div className={`${c}__card-content-cart-price`}>
                  {data?.discount ? 
                    <div className={`${c}__card-content-cart-price-discount`}>
                      <div className={`${c}__card-content-cart-price-discount-old`}>
                        {data.price}
                      </div>
                      <div className={`${c}__card-content-cart-price-discount-new`}>
                        <FontAwesomeIcon width={12} icon={faArrowRight} /> {Math.round(Number(data?.price) - (Number(data?.price) * Number(data.discount)) / 100)} lei
                      </div>
                    </div> 
                    : `${data?.price} lei`}
                </div>
                <div className={`${c}__card-content-cart-add`}>
                  <Button
                    variant='contained'
                    onClick={addToCart}
                    disabled={!quantity}
                    sx={{
                      backgroundColor: '#ff1647',
                      textTransform: 'none',
                      fontWeight: 'bold',
                      width: '100%',
                      '&:hover': { backgroundColor: '#cc1138' },
                    }}
                  >
                    {t('ADD_TO_CART')}
                  </Button>
                  <QuantityInput setQuantity={setQuantity} quantity={quantity} />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default ProductViewMobile