import React, { useEffect, useState } from 'react'
import { initReactI18next } from 'react-i18next'
import { resources } from './i18n'
import { Route, Routes } from 'react-router-dom'
import { useDeviceDetect } from './utils/use-device-detect'
import { ReactNotifications } from 'react-notifications-component'
import { ReactComponent as Loading } from './assets/icons/loading.svg'
import { getInfo } from './api/general-info'
import Home from './pages/home/Home'
import i18next from 'i18next'
import MainLayout from './layouts/MainLayout/MainLayout'
import InfoLayout from './layouts/InfoLayout/InfoLayout'
import SilderLayout from './layouts/SilderLayout/SilderLayout'
import CartPage from './pages/cart-page/CartPage'
import CheckoutPage from './pages/checkout-page/CheckoutPage'
import ProductView from './pages/product-view/ProductView'
import ProductViewLayout from './layouts/ProductViewLayout/ProductViewLayout'
import ProductViewMobile from './pages/product-view/ProductViewMobile/ProductViewMobile'
import AdminLayout from './layouts/AdminLayout/AdminLayout'
import ProductsPage from './pages/admin/pages/products-page/ProductsPage'
import CategoriesPage from './pages/admin/pages/categories-page/CategoriesPage'
import LocationsPage from './pages/admin/pages/locations-page/LocationsPage'
import LoginModal from './components/login-modal/LoginModal'
import GeneralPage from './pages/admin/pages/general-page/GeneralPage'
import DeliveryConditionsPage from './pages/delivery-conditions/DeliveryConditionsPage'
import IngredientsPage from './pages/admin/pages/ingredients-page/IngredientsPage'
import ProductsCostLayout from './layouts/ProductsCostLayout/ProductsCostLayout'
import 'react-notifications-component/dist/theme.css'
import ProductsCostPage from './pages/admin/pages/products-cost-page/ProductsCostPage'
import OrdersPage from './pages/admin/pages/orders-page/OrdersPage'
import StatisticsPage from './pages/admin/pages/statistics-page/StatisticsPage'

type Props = {}

const App = (props: Props) => {
  const [isI18nInitialized, setIsI18nInitialized] = useState(false)
  const {isMobile, isTablet} = useDeviceDetect()
  const [isLoading, setIsLoading] = useState(true)

  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem('cart') || '[]')
  )

  useEffect(()=>{
    setIsLoading(true)
    getInfo()
      .then(res => localStorage.setItem('info', JSON.stringify(res.data)))
      .finally(() => setIsLoading(false))
  },[])

  useEffect(() => {
    i18next
      .use(initReactI18next)
      .init({
        resources,
        lng: localStorage.getItem('language') ?? 'ro',
        fallbackLng: 'ro',
        interpolation: {
          escapeValue: false,
        },
      })
      .then(() => setIsI18nInitialized(true))
  }, [])

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart))
  }, [cart])

  if (!isI18nInitialized) {
    return null
  }

  if(isLoading) {
    return <div className='app-loading'>
      <Loading/>
    </div>
  }

  return (
    <div className='full-height'>
      <ReactNotifications />
      <Routes>
        <Route path='/' element={<MainLayout cart={cart} />}>
          <Route path='' element={<SilderLayout cart={cart} setCart={setCart}/>}>
            <Route path='' element={<Home />} />
            <Route path='/delivery-conditions' element={<DeliveryConditionsPage />} />
          </Route>
          <Route path='/cart' element={<InfoLayout cart={cart} setCart={setCart} />}>
            <Route path='' element={<CartPage />} />
            <Route path='checkout' element={<CheckoutPage />} />
          </Route>
          <Route path='/product' element={<ProductViewLayout cart={cart} setCart={setCart} />}>
            <Route path=':id' element={isMobile ? <ProductViewMobile cart={cart} setCart={setCart}/> : <ProductView cart={cart} setCart={setCart} />} />
          </Route>
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          <Route path="general" element={<GeneralPage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="categories" element={<CategoriesPage />} />
          <Route path="locations" element={<LocationsPage />} />
          <Route path="ingredients" element={<IngredientsPage />} />
          <Route path="costs" element={<ProductsCostLayout />}>
            <Route path=":id" element={<ProductsCostPage />} />
          </Route>
          <Route path="orders" element={<OrdersPage />} />
          <Route path="statistics" element={<StatisticsPage />} />
        </Route>
        <Route path='/login' element={<LoginModal isOpen />}/>
      </Routes>
    </div>
  )
}

export default App
