import React, { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ProductsMonthStatsModal from '../products-stats-modal/ProductsMonthStatsModal'
import ProductsYearStatsModal from '../products-stats-modal/ProductsYearStatsModal'
import './StatsCard.scss'

type Props = {
    stats: any
}

const c = 'stats-card'

const ProductsStatsCard = (props: Props) => {
  const {t} = useTranslation()
  const [value, setValue] = useState('0');
  const [isOpenMonth, setIsOpenMonth] = useState(false);
  const [isOpenYear, setIsOpenYear] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().toLocaleString('en-US', { month: 'long' }).toLowerCase());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const yearly = props.stats.yearly || []
  const months = Object.keys(yearly[yearly.length - 1].months)
  const monthValues = yearly[yearly.length - 1].months

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClickMonth = (month: string) => {
    setSelectedMonth(month)
    setIsOpenMonth(true)
  }

  const handleClickYear = (year: number) => {
    setSelectedYear(year)
    setIsOpenYear(true)
  }

  const calculateSoldProducts = (month: string) => {
    let total = 0

    monthValues[month].sold_products.forEach((product: any) => {
      total += product.quantity
    })

    return total
  }

  const calculateOrdersPerYear = (year: string) => {
    let total = 0

    props.stats.yearly.forEach((item: any) => {
      if(item.year === year) {
        item.sold_products.forEach((product: any) => {
          total += product.quantity
        })
      }
    })

    return total
  }

  const getSortedProducts = (products: any) => {
    const aggregatedProducts = products.reduce((acc: any, product: any) => {
      const existingProduct = acc.find((p: any) => p.name === product.name);
  
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        acc.push({ name: product.name, quantity: product.quantity });
      }
  
      return acc;
    }, []);
  
    return aggregatedProducts.sort((a:any, b:any) => b.quantity - a.quantity);
  };
  
  const sortedAllTimeProducts = () => {
    return getSortedProducts(props.stats.allTime.sold_products);
  };
  
  const sortedTodayProducts = () => {
    return getSortedProducts(props.stats.today.sold_products);
  };

  return (
    <div className={c}>
      <div className={`${c}__title`}>Produse Vandute</div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList variant="fullWidth" onChange={handleChange} sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#ff1647',
            },
            '& .MuiButtonBase-root.MuiTab-root': {
              color: 'black',
              transition: 'color 0.2s ease-in-out',
              '&:hover': {
                color: '#cc1138',
              },
              '&.Mui-selected': {
                color: '#ff1647',
              },
              '&.Mui-disabled': {
                color: 'lightgray',
              },
            },}}
          >
            <Tab label="Azi" value="0" />
            <Tab label="Pe Luna" value="1" />
            <Tab label="pe An" value="2" />
            <Tab label="Tot timpul" value="3" />
          </TabList>
        </Box>
        <TabPanel sx={{padding: '24px 0'}} value="0" className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Produs</TableCell>
                  <TableCell className='table-header-text'>Cantitate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTodayProducts().length ? sortedTodayProducts().map((product: any, index: number) => (
                  <TableRow key={index} className='table-body-row'>
                    <TableCell>
                      {product.name}
                    </TableCell>
                    <TableCell>
                      {product.quantity}
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell align='center' colSpan={2}>
                      Nu s-au vandut produse astazi
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="1" className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Luna</TableCell>
                  <TableCell className='table-header-text'>Produse Vandute</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {months.map((month, index) => (
                  <TableRow key={index} onClick={() => handleClickMonth(month)} className='table-body-row'>
                    <TableCell>
                      {t(month.toLocaleUpperCase())}
                    </TableCell>
                    <TableCell>
                      {calculateSoldProducts(month)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="2" className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Anul</TableCell>
                  <TableCell className='table-header-text'>Cantitate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.stats.yearly.map((item: any, index: number) => (
                  <TableRow key={index} onClick={() => handleClickYear(item.year)} className='table-body-row'>
                    <TableCell>
                      {t(item.year.toLocaleUpperCase())}
                    </TableCell>
                    <TableCell>
                      {calculateOrdersPerYear(item.year)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="3" className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Produs</TableCell>
                  <TableCell className='table-header-text'>Cantitate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAllTimeProducts().length ? sortedAllTimeProducts().map((product: any, index: number) => (
                  <TableRow key={index} className='table-body-row'>
                    <TableCell>
                      {product.name}
                    </TableCell>
                    <TableCell>
                      {product.quantity}
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell align='center' colSpan={2}>
                      Nu s-au vandut produse pana acum
                    </TableCell>
                  </TableRow>
                )}

              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </TabContext>
      <ProductsMonthStatsModal isOpen={isOpenMonth} setIsOpen={setIsOpenMonth} data={monthValues[selectedMonth]?.sold_products} selectedMonth={selectedMonth} />
      <ProductsYearStatsModal isOpen={isOpenYear} setIsOpen={setIsOpenYear} data={props.stats.yearly} selectedYear={selectedYear} />
    </div>
  )
}

export default ProductsStatsCard