import React, { useEffect, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { IProduct } from '../../../../types/products';
import AdminMenuItem from '../../components/admin-menu-item/AdminMenuItem';
import { ICategory } from '../../../../types/categories';

type Props = {
    product: any
    setProducts: (products: any) => void
    categories: ICategory[]
    getProductsData: (params: any) => void
}
const c = 'products-page'

const DropComponent = (props: Props) => {
  const [enabled, setEnabled] = useState(false);
  
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
  
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  
  if (!enabled) {
    return null;
  }
  
  return (
    <Droppable key={props.product.category_id} droppableId={`category-${props.product.category_id}`}>
      {(provided) => (
        <div 
          className={`${c}__menu-items-container-products`}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {
            props.product.items.map((item: any, i: number) => {
              return ( 
                <Draggable key={item._id} draggableId={item._id} index={i}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <AdminMenuItem
                        key={i}
                        categories={props.categories}
                        setData={props.setProducts}
                        data={item}
                        refetch={props.getProductsData}
                      />
                    </div>
                  )}
                </Draggable>
              )
            })
          }
        </div>
      )}
    </Droppable>
  );
}

export default DropComponent