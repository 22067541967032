import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import './ProductsStatsModal.scss'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    data?: any
    selectedMonth: string
}
const c = 'products-stats-modal'

const ProductsMonthStatsModal = (props: Props) => {
  const {t} = useTranslation()

  const sortedProducts = () => {
    return props.data?.sort((a: any, b: any) => b.quantity - a.quantity)
  }

  return (
    <ReactModal ariaHideApp={false} className='products-stats-modal-container' isOpen={props.isOpen}>
      <div className={c}>
        <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div>
        <div className={`${c}-title`}>{t(props.selectedMonth?.toLocaleUpperCase())}</div>
        <div className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Produs</TableCell>
                  <TableCell className='table-header-text'>Cantitate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts().length ? sortedProducts().map((product: any, index: number) => (
                  <TableRow key={index} className='table-body-row'>
                    <TableCell>
                      {product.name}
                    </TableCell>
                    <TableCell>
                      {product.quantity}
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell align='center' colSpan={2}>
                      Nu s-au vandut produse in acesta luna
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </ReactModal>
  )
}

export default ProductsMonthStatsModal