import Ro from './translations/ro.json';
import Ru from './translations/ru.json';

export const resources = {
  ro: {
    translation: Ro,
  },
  ru: {
    translation: Ru,
  },
};
