import React, { useState } from 'react'
import { faMobileScreen, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg'
import { INSTAGRAM_LINK } from '../../utils/constants'
import './ContactsButton.scss'

type Props = {}
const c = 'contacts-button'

const ContactsButton = (props: Props) => {
  const generalInfo = JSON.parse(localStorage.getItem('info') || '')
  const [open, setOpen] = useState(false)

  return (
    <div className={c}>
      <div className={`${c}__items ${open ? 'is-open' : ''}`}>
        <div className={`${c}__items-item`}>
          <a href={`tel:${generalInfo?.phone1 || generalInfo?.phone2}`} target="_blank" rel="noreferrer" >
            <FontAwesomeIcon icon={faPhoneAlt}/>
          </a>
        </div>
        <div className={`${c}__items-item`}> 
          <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer" >
            <InstagramIcon/>
          </a>
        </div>
      </div>
      <div className={`${c}__action`} onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faMobileScreen}/>
      </div>
    </div>
  )
}

export default ContactsButton