import { request } from '../request'

export const getInfo = () => {
  return request.get('/general-info')
}

export const editInfo = (params: any) => {
  return request.put('/general-info', params)
}

export const deleteImage = (imageUrl: string) => {
  return request.delete(`/general-info?imageUrl=${imageUrl}`)
}