import React, { useState } from 'react'
import { Button } from '@mui/material'
import QuantityInput from '../quantity-input/QuantityInput'
import { IProduct } from '../../types/products'
import './MenuItem.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDeviceDetect } from '../../utils/use-device-detect'
import { Store } from 'react-notifications-component'
import { serverUrl } from '../../request'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCartShopping } from '@fortawesome/free-solid-svg-icons'
import i18next from 'i18next'
import { ALCOOLIC_DRINKS_CATEGORY_ID, NON_ALCOOLIC_DRINKS_CATEGORY_ID } from '../../utils/constants'

type Props = {
  data?: IProduct
  setCart: any
  cart: any
  addToCartIcon?: boolean
}
const c = 'menu-item'

const MenuItem = (props: Props) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [quantity, setQuantity] = useState(1)
  const [imageLoaded, setImageLoaded] = useState(false);

  function addToCart() {
    const data = {
      ...props.data,
      quantity,
      price: props.data?.discount ? Math.round(Number(props.data?.price) - (Number(props.data?.price) * Number(props.data.discount)) / 100) : props.data?.price,
    }

    const itemIndex = props.cart.findIndex(
      (cartItem: IProduct) => cartItem._id === props.data?._id
    )

    if (itemIndex === -1) {
      props.setCart([...props.cart, data])
      localStorage.setItem('cart', JSON.stringify([...props.cart, data]))
    } else {
      const updatedCart = [...props.cart]

      updatedCart[itemIndex].quantity += quantity
      localStorage.setItem('cart', JSON.stringify(updatedCart))
      props.setCart(updatedCart)
    }


    Store.addNotification({
      title: 'SUCCESS',
      message: t('SUCCESSFULLY_ADDED'),
      container: 'top-center',
      type: "success",
      insert: "top",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000,
        onScreen: true
      }
    })
    
  }

  function handleClick(){
    navigate(`/product/${props.data?._id}`)
  }


  return (
    <div className={`${c} ${props.addToCartIcon ? 'small' : ''}`}>
      <div className={`${c}__image`}>
        <div className={`${c}__image-props`}>
          {props.data?.isNew && <div className={`${c}__image-props-prop`}>{t('NEW')}</div>}
          {props.data?.discount && <div className={`${c}__image-props-prop`}>-{props.data.discount}%</div>}
        </div>
        <img
          src={imageLoaded ? serverUrl + props.data?.image : ''}
          onClick={handleClick}
          alt='item'
          style={!imageLoaded ? { visibility: 'hidden' } : {}}
          onLoad={() => setImageLoaded(true)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = '/logo_bg.png';
          }}
        />
      </div>
      <div className={`${c}__info`}>
        <div className={`${c}__info-details`}>
          <div className={`${c}__info-details-name`} onClick={handleClick}>{(i18next.language === 'ru' && props.data?.ru_name) ? props.data?.ru_name : props.data?.name}</div>
          <div className={`${c}__info-details-price_weight`}>
            <div className={`${c}__info-details-price_weight-price`}>
              {props.data?.discount ? 
                <div className={`${c}__info-details-price_weight-price-discount`}>
                  <div className={`${c}__info-details-price_weight-price-discount-old`}>
                    {props.data.price}
                  </div>
                  <div className={`${c}__info-details-price_weight-price-discount-new`}>
                    <FontAwesomeIcon width={12} icon={faArrowRight} /> {Math.round(Number(props.data?.price) - (Number(props.data?.price) * Number(props.data.discount)) / 100)} lei
                  </div>
                </div> 
                : `${props.data?.price} lei`}
            </div>
            <div className={`${c}__info-details-price_weight-weight`}>
              {props.data?.weight ? `${props.data?.weight}g` : ''} {props.data?.weight && props.data.pieces ? ' - ' : ''} {props.data?.pieces ? `${props.data?.pieces} ${t('PIECES')}` : ''}
            </div>
          </div>
        </div>

        <div className={`${c}__info-add_to_cart`}>
          <Button
            variant='contained'
            onClick={addToCart}
            disabled={!quantity}
            sx={{
              backgroundColor: '#ff1647',
              textTransform: 'none',
              fontWeight: 'bold',
              width: '100%',
              '&:hover': { backgroundColor: '#cc1138' },
            }}
          >
            {props.addToCartIcon ? <FontAwesomeIcon icon={faCartShopping}/> :  t('ADD_TO_CART') }
          </Button>
          {!props.addToCartIcon && <QuantityInput setQuantity={setQuantity} quantity={quantity} />}
        </div>
      </div>
    </div>
  )
}

export default MenuItem
