import React, { useState } from 'react'
import { Button } from '@mui/material'
import { IProduct } from '../../../../types/products'
import './AdminMenuItem.scss'
import { serverUrl } from '../../../../request'
import ProductsModal from '../../pages/products-page/products-modal/ProductsModal'
import { deleteProduct } from '../../../../api/products'
import { Store } from 'react-notifications-component'
import { useTranslation } from 'react-i18next'

type Props = {
  categories?: any
  data?: IProduct
  setData?: any
  refetch?: any
}
const c = 'admin-menu-item'

const AdminMenuItem = (props: Props) => {
  const [openEdit, setOpenEdit] = useState(false)
  const {t} = useTranslation()
  const splitDescription = props.data?.description.split('_')
 
  function handleDeleteProduct(){
    props.setData((prev: any) => {
      return prev.map((item: any) => {
        if (item.items) {
          item.items = item.items.filter((product: any) => product._id !== props.data?._id);
        }

        return item;
      });
    });
    deleteProduct(String(props.data?._id)).then(()=>{
      Store.addNotification({
        title: 'SUCCESS',
        message: 'Produsul a fost sters cu succes!',
        container: 'top-center',
        type: "success",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    })
  }

  return (
    <div className={c}>
      <div className={`${c}__image`}>
        <div className={`${c}__image-props`}>
          {props.data?.isNew && <div className={`${c}__image-props-prop`}>Nou</div>}
          {props.data?.isAdditional && <div className={`${c}__image-props-prop`}>Aditional</div>}
          {props.data?.discount && <div className={`${c}__image-props-prop discount`}>-{props.data?.discount}%</div>}
        </div>
        <img 
          src={serverUrl + props.data?.image}
          alt='item' 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;

            currentTarget.src = '/logo_bg.png';
          }}
        />
      </div>
      <div className={`${c}__info`}>
        <div className={`${c}__info-details`}>
          <div className={`${c}__info-details-name`}>{props.data?.name}</div>
          <div className={`${c}__info-details-description`}>{splitDescription?.map((item, index: number) => <div key={index}>{item.trim()}</div>)}</div>
          <div className={`${c}__info-details-name`}>
            {props.data?.weight}g {props.data?.pieces ? ` - ${props.data?.pieces} ${t('PIECES')}` : ''}
          </div>
          <div className={`${c}__info-details-price`}>
            {props.data?.price} lei
          </div>
        </div>

        <div className={`${c}__info-add_to_cart`}>
          <Button
            variant='contained'
            onClick={() => setOpenEdit(true)}
            sx={{
              backgroundColor: '#ff1647',
              textTransform: 'none',
              fontWeight: 'bold',
              width: '100%',
              '&:hover': { backgroundColor: '#cc1138' },
            }}
          >
            Editeaza
          </Button>
          <Button
            variant='contained'
            onClick={handleDeleteProduct}
            sx={{
              backgroundColor: '#ff1647',
              textTransform: 'none',
              fontWeight: 'bold',
              width: '100%',
              '&:hover': { backgroundColor: '#cc1138' },
            }}
          >
            Sterge
          </Button>
        </div>
      </div>
      <ProductsModal isOpen={openEdit} setIsOpen={setOpenEdit} categories={props.categories} data={props.data} refetch={props.refetch} />
    </div>
  )
}

export default AdminMenuItem
