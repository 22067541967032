import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import { IProduct } from '../../types/products'
import { useDeviceDetect } from '../../utils/use-device-detect'
import NavbarMobile from '../../components/navbar/NavbarMobile/NavbarMobile'
import Sidebar from '../../components/sidebar/Sidebar'
import './MainLayout.scss'
import ContactsButton from '../../components/contacts-button/ContactsButton'

type Props ={
  cart: any
}
const MainLayout = (props: Props) => {
  const {isMobile, isTablet} = useDeviceDetect()
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(()=>{
    if(isOpen){
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }
  },[isOpen])

  return (
    <div className='main-layout'>
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className='body-container'>
        {!(isMobile || isTablet) ? <Navbar searchValue={searchValue} setSearchValue={setSearchValue} /> : <NavbarMobile setIsOpen={setIsOpen} cart={props.cart} searchValue={searchValue} setSearchValue={setSearchValue} />}
        <Outlet context={{searchValue}} />
      </div>
      {!(isMobile || isTablet) && <Footer />}
      {isMobile && <ContactsButton />}
    </div>
  )
}

export default MainLayout
