import React from 'react'
import { Outlet } from 'react-router-dom'
import { IProduct } from '../../types/products'
import HeaderMenu from '../../components/header-menu/HeaderMenu'
import './InfoLayout.scss'
import { useDeviceDetect } from '../../utils/use-device-detect'

type Props = {
  cart: IProduct[]
  setCart: (cart: IProduct[]) => void
}
const c = 'info-layout'

const InfoLayout = ({ cart, setCart }: Props) => {
  const {isMobile, isTablet} = useDeviceDetect()

  return (
    <div className={c}>
      <div className={`${c}__container`}>
        <div className={`${c}__container-header-background`}>
          <div className={`${c}__container-header-background-image`} />
          <div className={`${c}__container-header-background-overlay`} />
        </div>
        <div className={`${c}__container-body`}>
          <div className={`${c}__container-body-content`}>
            {!(isMobile || isTablet) && (
              <HeaderMenu />
            )}
            <div className={`${c}__container-body-content-outlet`}>
              <Outlet context={{ cart, setCart }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoLayout
