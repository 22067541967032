import React from 'react'
import Logo from '../../assets/images/logo_text.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next'
import { useDeviceDetect } from '../../utils/use-device-detect'
import { useNavigate } from 'react-router-dom'
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import './InfoSection.scss'
import { serverUrl } from '../../request'

type Props = {}

const c = 'info-section'

const InfoSection = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {isMobile, isTablet} = useDeviceDetect()
  const generalInfo = JSON.parse(localStorage.getItem('info') || '')

  return (
    <div className={c}>
      <div className={`${c}__container`}>
        {!(isMobile) && (
          <div className={`${c}__container-menu`}>
            <div className={`${c}__container-menu-logo`}>
              <img src={Logo} alt='logo' onClick={() => navigate('/')} />
            </div>
            <div className={`${c}__container-menu-links`}>
              <div className={`${c}__container-menu-links-link`} onClick={() => navigate('/')}>{t('MENU')}</div>
              {/* <div className={`${c}__container-menu-links-link`}>{t('CONTACTS')}</div> */}
              <div className={`${c}__container-menu-links-link`} onClick={() => navigate('/delivery-conditions')}>
                {t('DELIVERY_CONDITIONS')}
              </div>
            </div>
          </div>
        )}
        <div className={`${c}__container-products`}>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            className={`${c}__swiper-container`}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            loop
            autoplay={{ delay: 3000 }}
          >
            {generalInfo.images.map((image: string) => (
              <SwiperSlide key={image}>
                <img
                  className='slider-img'
                  src={serverUrl + image}
                  alt='img'
                />
              </SwiperSlide>
            ))}
            {/* <SwiperSlide>
              <img
                className='slider-img'
                src='https://laplacinte.md/public/slider/4011cb0bfbfc85581c78afea254c494c.webp'
                alt='img'
              />
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default InfoSection
