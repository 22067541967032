import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getCategories } from '../../../../api/categories'
import { IProductsParams } from '../../../../types/products'
import { editProduct, getProducts } from '../../../../api/products'
import { ICategory } from '../../../../types/categories'
import { ReactComponent as Loading } from '../../../../assets/icons/loading.svg'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from '@mui/material'
import ProductsModal from './products-modal/ProductsModal'
import AdminMenuItem from '../../components/admin-menu-item/AdminMenuItem'
import './ProductsPage.scss'
import DropComponent from './DropComponent'

type Props = {}
const c = 'products-page'


const ProductsPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [categories, setCategories] = useState<ICategory[]>([])
  const [products, setProducts] = useState<any[]>([])
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [searchParams] = useSearchParams()
  const category_id = searchParams.get('category_id')

  function getCategoriesData() {
    getCategories()
      .then((res) => {
        setCategories(res?.data)
      })
      .catch((err) => console.log(err))
  }
  
  function getProductsData(params: IProductsParams) {
    setLoadingProducts(true)
    getProducts({ ...params })
      .then((res) => {
        setProducts(res?.data)
      })
      .finally(() => {
        setLoadingProducts(false)
      })
      .catch((err) => console.log(err))
  }
  
  useEffect(() => {
    getCategoriesData()
  }, [])
  
  useEffect(() => {
    getProductsData({ category_id })
  }, [category_id])

  const onDragEnd = (result: any) => {
    const { source, destination } = result;
  
    // If the item is dropped outside the list
    if (!destination) {
      return;
    }
  
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return; // Item was dropped back in the same position
    }
  
    const updatedProducts = products.map((product) => {
      if (`category-${product.category_id}` === source.droppableId) {
        const items = Array.from(product.items);
  
        const [reorderedItem] = items.splice(source.index, 1);

        items.splice(destination.index, 0, reorderedItem);
  
        // Update the positions for all items
        const updatedItems = items.map((item: any, index) => ({
          ...item,
          position: index,
        }));
  
        // Assuming editProduct is a function that updates the item in the database
        updatedItems.forEach((item) => {
          editProduct({...item, position: item.position }, item._id);
        });
  
        return { ...product, items: updatedItems };
      }
  
      return product;
    });
  
    setProducts(updatedProducts);
  };

  return (
    <div className={c}>
      <div className={`${c}__add_product`}>
        <div className={`${c}__add_product-btn`}>
          <Button
            variant='contained'
            onClick={() => setIsOpen(true)}
            sx={{
              backgroundColor: '#ff1647',
              textTransform: 'none',
              fontWeight: 'bold',
              width: '100%',
              '&:hover': { backgroundColor: '#cc1138' },
            }}
          >
            Adauga
          </Button>
        </div>
      </div>

      <div
        className={`${c}__menu-items${
          !products?.[0]?.items.length || loadingProducts ? ' no-data' : ''
        }`}
      >
        {loadingProducts ? (
          <Loading />
        ) : products?.length ? (
          products.map((product: any, index) => {
            return (
              <div key={index} className={`${c}__menu-items-container`}> 
                {!category_id && <div className={`${c}__menu-items-container-title`}>{product.category_name}</div>}
                <DragDropContext onDragEnd={onDragEnd}>
                  <DropComponent product={product} setProducts={setProducts} getProductsData={getProductsData} categories={categories}></DropComponent>
                </DragDropContext>
              </div>
            )
          })
        ) : (
          <div className={`${c}__menu-items-no_data`}>
            Nu sunt date
          </div>
        )}
      </div>
      <ProductsModal isOpen={isOpen} setIsOpen={setIsOpen} categories={categories} refetch={getProductsData} />
    </div>
  )
}

export default ProductsPage