import React, { useEffect, useState } from 'react'
import { getStatistics } from '../../../../api/statistics'
import { CircularProgress } from '@mui/material'
import PriceStatsCard from './StatsCard/PriceStatsCard'
import './StatisticsPage.scss'
import DeliveryStatsCard from './StatsCard/DeliveryStatsCard'
import OrderStatsCard from './StatsCard/OrderStatsCard'
import ProductsStatsCard from './StatsCard/ProductsStatsCard'
import LocationsStatsCard from './StatsCard/LocationsStatsCard'
import { getOrders } from '../../../../api/order'

const c = 'statistics-page'

const StatisticsPage = () => {
  const [stats, setStats] = useState<any>(null)
  const [orders, setOrders] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getOrders().then((res) => {
      setOrders(res.data)
    })

    getStatistics()
      .then((res) => {
        setStats(res.data)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }, [])

  if (!orders.length) {
    return <div className='no-orders'>Nu este nici o comanda</div>
  }

  return (
    <div className={c}>
      <div className={`${c}__container`}>
        {loading ? <CircularProgress /> : (
          <div className={`${c}__container-cards`}>
            <PriceStatsCard stats={stats} />
            <div className={`${c}__container-cards-separator`} />
            <DeliveryStatsCard stats={stats} />
            <div className={`${c}__container-cards-separator`} />
            <OrderStatsCard stats={stats} />
            <div className={`${c}__container-cards-separator`} />
            <ProductsStatsCard stats={stats} />
            <div className={`${c}__container-cards-separator`} />
            <LocationsStatsCard stats={stats} />
          </div>
        )}
      </div>
    </div>
  )
}

export default StatisticsPage