import React, { useEffect, useState } from 'react'
import CategoriesSlider from '../../components/categories-slider/CategoriesSlider'
import MenuItem from '../../components/menu-item/MenuItem'
import { getCategories } from '../../api/categories'
import { getProducts } from '../../api/products'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { IProductsParams } from '../../types/products'
import { ICategory } from '../../types/categories'
import { ReactComponent as Loading } from '../../assets/icons/loading.svg'
import { useTranslation } from 'react-i18next'
import DaysOffModal from './DaysOffModal/DaysOffModal'
import './Home.scss'
import AnnouncementModal from './AnnouncementModal/AnnouncementModal'

type Props = {}
const c = 'home-page'

const Home = (props: Props) => {
  const [categories, setCategories] = useState<ICategory[]>([])
  const [products, setProducts] = useState<any[]>([])
  const [filteredProducts, setFilteredProducts] = useState<any[]>(products)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openAnnouncement, setOpenAnnouncement] = useState(false)
  const { cart, setCart, searchValue } = useOutletContext<any>()
  const [searchParams] = useSearchParams()
  const category_id = searchParams.get('category_id')
  const generalInfo = JSON.parse(localStorage.getItem('info') || '')
  const {t, i18n} = useTranslation()

  const getTranslatedName = (item: any) => {
    if (i18n.language === 'ru' && item?.category_name_ru) {
      return item?.category_name_ru;
    }

    return item?.category_name;
  };

  function getCategoriesData() {
    getCategories()
      .then((res) => {
        setCategories(res?.data)
      })
      .catch((err) => console.log(err))
  }

  async function getProductsData(params: IProductsParams) {
    setLoadingProducts(true)
    await getProducts({ ...params })
      .then((res) => {
        setProducts(res?.data)
      })
      .finally(() => {
        setLoadingProducts(false)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getCategoriesData()
  }, [])

  useEffect(() => {
    getProductsData({ category_id })
  }, [category_id])

  useEffect(()=>{
    scrollTo({top: 0, behavior: 'smooth'})
  },[])
  
  useEffect(()=>{
    if(generalInfo?.dayOff){
      setOpenModal(true)
    }

    if(generalInfo?.announcement){
      setOpenAnnouncement(true)
    }
  }, [])


  useEffect(() => {
    setFilteredProducts(() => {
      if (searchValue === '') {
        return products;
      } else {
        return products.map((product: any) => {
          const updatedItems = product.items?.filter(
            (item: any) =>
              (item.name && item.name?.toLowerCase().includes(searchValue.toLowerCase()))
              ||
              (item.ru_name && item.ru_name?.toLowerCase().includes(searchValue.toLowerCase()))
          );

          return {
            ...product,
            items: updatedItems,
          };
        });
      }
    });
  },[searchValue, products])

  return (
    <div className={c}>
      <CategoriesSlider data={categories} />
      <div
        className={`${c}__menu-items${
          (!filteredProducts || filteredProducts.every(product => product.items.length === 0)) || loadingProducts ? ' no-data' : ''
        }`}
      >
        {loadingProducts ? (
          <Loading />
        ) : filteredProducts && filteredProducts.some(product => product.items.length) ? (
          filteredProducts.map((product: any, index) => {
            if(!product.items.length) {return null}

            return (
              <div key={index} className={`${c}__menu-items-container`}> 
                {!category_id && <div className={`${c}__menu-items-container-title`}>{getTranslatedName(product)}</div>}
                <div className={`${c}__menu-items-container-products`}>
                  {
                    product.items.map((item: any, i: number) => {
                      return ( 
                        <MenuItem key={i} data={item} cart={cart} setCart={setCart} />
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        ) : (
          <div className={`${c}__menu-items-no_data`}>
            Nu sunt produse pentru aceasta categorie la moment
          </div>
        )}
      </div>
      {openModal && <DaysOffModal isOpen={openModal} setIsOpen={setOpenModal}/>}
      {openAnnouncement && <AnnouncementModal isOpen={openAnnouncement} setIsOpen={setOpenAnnouncement}/>}
    </div>
  )
}

export default Home
