import React from 'react'
import { Outlet } from 'react-router-dom'
import Cart from '../../components/cart/Cart'
import { IProduct } from '../../types/products'
import HeaderMenu from '../../components/header-menu/HeaderMenu'
import './ProductViewLayout.scss'
import { useDeviceDetect } from '../../utils/use-device-detect'

type Props = {
  cart: IProduct[]
  setCart: (cart: IProduct[]) => void
}
const c = 'product-view-layout'

const ProductViewLayout = ({ cart, setCart }: Props) => {
  const {isMobile, isTablet} = useDeviceDetect()

  return (
    <div className={c}>
      <div className={`${c}__header-background`}>
        <div className={`${c}__header-background-image`} />
        <div className={`${c}__header-background-overlay`} />
      </div>
      <div className={`${c}__body`}>
        <div className={`${c}__body-container`}>
          {!isMobile && (
            <HeaderMenu />
          )}
          <div className={`${c}__body-container-menu-content`}>
            <Outlet context={{ cart, setCart }} />
          </div>
        </div>
        {!(isMobile || isTablet) && (
          <div className={`${c}__body-cart`}>
            <Cart cart={cart} setCart={setCart} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductViewLayout
