import React, { useEffect, useState } from 'react'
import QuantityInput from '../../quantity-input/QuantityInput'
import { IProduct } from '../../../types/products'
import './CartItem.scss'
import { serverUrl } from '../../../request'
import i18next from 'i18next'

type Props = {
  data?: any
  setCart: any
}
const c = 'cart-item'

const CartItem = ({ data, setCart }: Props) => {
  const cartData = JSON.parse(localStorage.getItem('cart') || '')
  const [quantity, setQuantity] = useState(data?.quantity || 1)

  useEffect(() => {
    setQuantity(data?.quantity)
  }, [data?.quantity])

  useEffect(() => {
    const newCart = [...cartData]

    const cartItemIndex = newCart.findIndex(
      (item: IProduct) => item._id === data._id
    )

    if (cartItemIndex !== -1) {
      newCart[cartItemIndex].quantity = quantity

      if (quantity < 1 ) {
        newCart.splice(cartItemIndex, 1)
      }
    }

    setCart(newCart)
  }, [quantity])

  return (
    <div className={c}>
      <div className={`${c}__info`}>
        <div className={`${c}__info-image`}>
          <img
            src={serverUrl + data?.image}
            alt='item'       
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;

              currentTarget.src = '/logo_bg.png';
            }}
          />
        </div>
        <div className={`${c}__info-text`}>
          <div className={`${c}__info-text-title`}>{(i18next.language === 'ru' && data?.ru_name) ? data?.ru_name : data?.name}</div>
          <div className={`${c}__info-text-quantity`}>
            <QuantityInput
              quantity={quantity}
              setQuantity={setQuantity}
              cartItem
            />
          </div>
        </div>
      </div>
      <div className={`${c}__price`}>{data?.price} lei</div>
    </div>
  )
}

export default CartItem
