import React, { useState } from 'react'
import { faClock, faPhone, faSearch} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LanguagesDropdown from '../languages-dropdown/LanguagesDropdown'
import { Trans, useTranslation } from 'react-i18next'
import { styled, alpha } from '@mui/material/styles';
import { InputBase } from '@mui/material'
import './Navbar.scss'

type Props = {
  searchValue?: string
  setSearchValue?: (value: string) => void
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '10ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const c = 'main-navbar'

const Navbar = (props: Props) => {
  const {t} = useTranslation()
  const [scrollTimeout, setScrollTimeout] = useState<any>(null);
  const generalInfo = JSON.parse(localStorage.getItem('info') || '')

  function handleChange(e: any){
    props.setSearchValue?.(e.target.value);

    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    const timeout = setTimeout(() => {
      window.scrollTo({ top: window.innerHeight - 100, behavior: 'smooth' });
    }, 500);

    setScrollTimeout(timeout);
  }

  return (
    <nav className={c}>
      <div className={`${c}__container`}>
        <div className={`${c}__container-nav-left`}>
          <div className={`${c}__container-nav-left-container`}>
            <FontAwesomeIcon icon={faPhone} />
            <div className={`${c}__container-nav-left-container-phone`}>
              <div className={`${c}__container-nav-left-container-phone-number`}>{generalInfo?.phone1}</div>
              <div className={`${c}__container-nav-left-container-phone-number`}>{generalInfo?.phone2}</div>
            </div>
          </div>
          <div className={`${c}__container-nav-left-schedule`}>
            <FontAwesomeIcon icon={faClock} />
            <Trans i18nKey='DELIVERY_SCHEDULE' values={{minTime: generalInfo?.minTime, maxTime: generalInfo?.maxTime}} />
          </div>
          <Search>
            <SearchIconWrapper>
              <FontAwesomeIcon icon={faSearch} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={t('SEARCH')}
              value={props.searchValue}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </div>
        <div className={`${c}__container-nav-right`}>
          <div className={`${c}__container-nav-right-language`}>
            <LanguagesDropdown />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
