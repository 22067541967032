import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import './DayStatsModal.scss'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    data?: any
    selectedMonth: string
}
const c = 'day-stats-modal'

const DayStatsModal = (props: Props) => {
  const {t} = useTranslation()
  const dayValues = Object.keys(props.data)

  return (
    <ReactModal ariaHideApp={false} className='day-stats-modal-container' isOpen={props.isOpen}>
      <div className={c}>
        <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div>
        <div className={`${c}-title`}>{t(props.selectedMonth?.toLocaleUpperCase())}</div>
        <div className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Ziua</TableCell>
                  <TableCell align="right" className='table-header-text'>Cecul Mediu</TableCell>
                  <TableCell align='right' className='table-header-text'>Nr. Comenzi</TableCell>
                  <TableCell align='right' className='table-header-text'>Livrare</TableCell>
                  <TableCell align='right' className='table-header-text'>Cost</TableCell>
                  <TableCell align="right" className='table-header-text'>Vanzari</TableCell>
                  <TableCell align="right" className='table-header-text'>Profit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dayValues.map((day: any, index) => (
                  <TableRow key={index} className='table-body-row'>
                    <TableCell>
                      {t(day.toLocaleUpperCase())}
                    </TableCell>
                    <TableCell align="right">{(props.data[day]?.sales / props.data[day]?.orders_count || 0).toFixed(2)} lei</TableCell>
                    <TableCell align="right">{props.data[day]?.orders_count}</TableCell>
                    <TableCell align="right">{props.data[day]?.delivery_sales}</TableCell>
                    <TableCell align="right">{props.data[day]?.cost.toFixed(2)} lei</TableCell>
                    <TableCell align="right">{props.data[day]?.sales.toFixed(2)} lei</TableCell>
                    <TableCell align="right">{props.data[day]?.profit.toFixed(2)} lei</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </ReactModal>
  )
}

export default DayStatsModal