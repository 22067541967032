import React from 'react'
import { useTranslation } from 'react-i18next'

import { MenuItem, Select, Avatar } from '@mui/material'
import { useDeviceDetect } from '../../utils/use-device-detect'

type languageOptionsType = {
  [key: string]: {
    icon: string
    name: string
  }
}

const languageOptions: languageOptionsType = {
  ru: {
    icon: '/flags/ru.png',
    name: 'Ru',
  },
  ro: {
    icon: '/flags/ro.png',
    name: 'Ro',
  },
}

const LanguagesDropdown = () => {
  const { i18n } = useTranslation()
  const {isMobile, isTablet} = useDeviceDetect()

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language)
    localStorage.setItem('language', language)
  }

  return (
    <Select
      autoWidth
      variant='outlined'
      value={i18n.language}
      sx={{
        display: 'flex',
        fontSize: isMobile ? '12px' : '14px',
        height: 30,
        color: 'white',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: 0,
          },
        '.MuiSelect-select.MuiSelect-outlined':{
          paddingRight: isMobile ? '14px !important' : '24px',
          gap: isMobile ? '10px !important' : '16px',
        },
        '.MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          color: 'white',
          gap: 2,
        },
      }}
      onChange={(e) => handleLanguageChange(e.target.value as string)}
      IconComponent={() => isMobile && null}
    >
      {Object.entries(languageOptions).map(([key, { icon, name }]) => (
        <MenuItem
          key={key}
          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
          value={key}
        >
          <Avatar sx={isMobile ? { width: 10, height: 10 } : { width: 15, height: 15 }} src={icon} alt={name}/>
          {name.toUpperCase()}
        </MenuItem>
      ))}
    </Select>
  )
}

export default LanguagesDropdown
