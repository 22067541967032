import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Store } from 'react-notifications-component'
import LocationsModal from './locations-modal/LocationsModal'
import './LocationsPage.scss'
import { deleteLocation, getLocations } from '../../../../api/locations'

const c = 'locations-page'

const LocationsPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [location, setLocation] = useState<any>()
  const [locations, setLocations] = useState<any[]>([])
  
  function getLocationsData() {
    getLocations()
      .then((res) => {
        setLocations(res?.data)
      })
      .catch((err) => console.log(err))
  }

  function handleDelete(locationId: string){
    setLocations((prev: any) => prev.filter((location: any) => locationId !== location._id))

    deleteLocation(locationId).then(() => {
      Store.addNotification({
        title: 'SUCCESS',
        message: 'Sters cu succes!',
        container: 'top-center',
        type: "success",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    })
  }

  function handleEdit(location: any){
    setIsOpenEdit(true)
    setLocation(location)
  }
  
  useEffect(() => {
    getLocationsData()
  }, [])
  
  return (
    <div className={c}>
      <div className={`${c}__add_product`}>
        <div className={`${c}__add_product-btn`}>
          <Button
            variant='contained'
            onClick={() => setIsOpen(true)}
            sx={{
              backgroundColor: '#ff1647',
              textTransform: 'none',
              fontWeight: 'bold',
              width: '100%',
              '&:hover': { backgroundColor: '#cc1138' },
            }}
          >
            Adauga
          </Button>
        </div>
      </div>
      <div className={`${c}__locations`}>
        {locations.map((location) => (
          <div key={location._id} className={`${c}__locations-item`}>
            <div className={`${c}__locations-item-title`}>{location.label}</div>
            <div className={`${c}__locations-item-title`}>{location.delivery_price} lei</div>
            <div className={`${c}__locations-item-actions`}>
              <div className={`${c}__locations-item-actions-btn`}> 
                <Button
                  variant='contained'
                  onClick={() => handleEdit(location)}
                  sx={{
                    backgroundColor: '#ff1647',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    width: 'fit-content',
                    '&:hover': { backgroundColor: '#cc1138' },
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </div>
              <div className={`${c}__locations-item-actions-btn`}> 
                <Button
                  variant='contained'
                  onClick={() => handleDelete(location._id)}
                  sx={{
                    backgroundColor: '#ff1647',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    width: 'fit-content',
                    '&:hover': { backgroundColor: '#cc1138' },
                  }}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <LocationsModal isOpen={isOpen} setIsOpen={setIsOpen} refetch={getLocationsData} />
      <LocationsModal isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} data={location} refetch={getLocationsData} />
    </div>
  )
}

export default LocationsPage