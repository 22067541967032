import React, { useEffect } from 'react'
import AdminNavbar from '../../components/admin-navbar/AdminNavbar'
import AdminSidebar from '../../components/admin-sidebar/AdminSidebar'
import { Outlet } from 'react-router-dom'
import './AdminLayout.scss'
import { authCheck } from '../../api/auth'

type Props = {}
const c = 'admin-layout'

const AdminLayout = (props: Props) => {

  useEffect(() => {
    authCheck()
  },[])

  return (
    <div className={c}>
      {/* <AdminNavbar /> */}
      <div className={`${c}__info`}>
        <div className={`${c}__info-sidebar`}><AdminSidebar /></div>
        <div className={`${c}__info-content`}><Outlet /></div>
      </div>
    </div>
  )
}

export default AdminLayout