import axios, { AxiosResponse } from 'axios';
import { Store } from 'react-notifications-component';

// eslint-disable-next-line import/exports-last
export const serverUrl = ["127.0.0.1", "localhost"].includes(window.location.hostname) ? "http://localhost:8000" : ''

const baseURL =  `${serverUrl}/api`

const axiosRequest = axios.create({
  baseURL,
  timeout: 5 * 60 * 1000,
});


axiosRequest.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('token');

  config.baseURL = baseURL;

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

axiosRequest.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  (error) => {
    if (error.request && error.request.status == 401) {
      Store.addNotification({
        title: 'ERROR',
        message: 'a expirat sesiunea',
        container: 'top-center',
        type: "danger",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
      window.location.replace(`/login`);
    }

    if (error.request && error.request.status === 400) {
      return Promise.reject(error)
    }
  }
);

export const request = axiosRequest
