import React, { useState } from 'react'
import LocationsMonthStatsModal from '../locations-stats-modal/LocationsMonthStatsModal'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import './StatsCard.scss'
import LocationsYearStatsModal from '../locations-stats-modal/LocationsYearStatsModal'

type Props = {
    stats: any
}

const c = 'stats-card'

const LocationsStatsCard = (props: Props) => {
  const date = new Date();
  const currentMonth = date.toLocaleString('en-US', { month: 'long' }).toLowerCase();
  const {t} = useTranslation()
  const [value, setValue] = useState('0');
  const [isOpenMonth, setIsOpenMonth] = useState(false);
  const [isOpenYear, setIsOpenYear] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(date.getFullYear());

  const months = Object.keys(props.stats.yearly?.[props.stats.yearly?.length - 1]?.months)
  const monthValues = props.stats.yearly?.[props.stats.yearly?.length - 1]?.months

  const formatLocations = (type: string) => {
    if(type === 'month') {
      return Object.keys(monthValues[selectedMonth].locations).map((location: any) => {
        return {
          name: location,
          quantity: monthValues[selectedMonth].locations[location]
        }
      })
    }

    if(type === 'yearly') {
      return Object.keys(props.stats.yearly[props.stats.yearly.length - 1].locations).map((location: any) => {
        return {
          name: location,
          quantity: props.stats.yearly[props.stats.yearly.length - 1].locations[location]
        }
      })
    }

    return Object.keys(props.stats[type].locations).map((location: any) => {
      return {
        name: location,
        quantity: props.stats[type].locations[location]
      }
    })
  }

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClickMonth = (month: string) => {
    setSelectedMonth(month)
    setIsOpenMonth(true)
  }

  const handleClickYear = (year: number) => {
    setSelectedYear(year)
    setIsOpenYear(true)
  }

  const calculateOrdersPerMonth = (month: string) => {
    let total = 0

    Object.values(monthValues[month]?.locations).forEach((quantity: any) => {
      total += quantity
    })

    return total
  }

  const calculateOrdersPerYear = (year: any) => {
    let total = 0

    props.stats.yearly.forEach((item: any) => {
      if(item.year === year) {
        Object.values(item?.locations).forEach((quantity: any) => {
          total += quantity
        })
      }
    })

    return total
  }

  const getSortedLocations = (locations: any) => {
    const aggregatedLocations = locations?.reduce((acc: any, location: any) => {
      const existingLocation = acc.find((p: any) => p.name === location.name);
  
      if (existingLocation) {
        existingLocation.quantity += location.quantity;
      } else {
        acc.push({ name: location.name, quantity: location.quantity });
      }
  
      return acc;
    }, []);
  
    return aggregatedLocations.sort((a:any, b:any) => b.quantity - a.quantity);
  };
  
  const sortedAllTimeLocations = () => {
    return getSortedLocations(formatLocations('allTime'));
  };
  
  
  const sortedTodayLocations = () => {
    return getSortedLocations(formatLocations('today'));
  };

  return (
    <div className={c}>
      <div className={`${c}__title`}>Comenzi pe Localitati</div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList variant="fullWidth" onChange={handleChange} sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#ff1647',
            },
            '& .MuiButtonBase-root.MuiTab-root': {
              color: 'black',
              transition: 'color 0.2s ease-in-out',
              '&:hover': {
                color: '#cc1138',
              },
              '&.Mui-selected': {
                color: '#ff1647',
              },
              '&.Mui-disabled': {
                color: 'lightgray',
              },
            },}}
          >
            <Tab label="Azi" value="0" />
            <Tab label="Pe Luna" value="1" />
            <Tab label="pe An" value="2" />
            <Tab label="Tot timpul" value="3" />
          </TabList>
        </Box>
        <TabPanel sx={{padding: '24px 0'}} value="0" className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Localitate</TableCell>
                  <TableCell className='table-header-text'>Nr. de Comenzi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTodayLocations().length ? sortedTodayLocations().map((location: any, index: number) => (
                  <TableRow key={index} className='table-body-row'>
                    <TableCell>
                      {location.name}
                    </TableCell>
                    <TableCell>
                      {location.quantity}
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell align='center' colSpan={2}>
                      Nu s-au inregistrat comenzi astazi
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="1" className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Luna</TableCell>
                  <TableCell className='table-header-text'>Nr. de Comenzi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {months.map((month, index) => (
                  <TableRow key={index} onClick={() => handleClickMonth(month)} className='table-body-row'>
                    <TableCell>
                      {t(month.toLocaleUpperCase())}
                    </TableCell>
                    <TableCell>
                      {calculateOrdersPerMonth(month)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="2" className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Anul</TableCell>
                  <TableCell className='table-header-text'>Nr. de Comenzi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.stats.yearly.map((item: any, index: number) => (
                  <TableRow key={index} onClick={() => handleClickYear(item.year)} className='table-body-row'>
                    <TableCell>
                      {t(item.year.toLocaleUpperCase())}
                    </TableCell>
                    <TableCell>
                      {calculateOrdersPerYear(item.year)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="3" className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Localitate</TableCell>
                  <TableCell className='table-header-text'>Nr. de Comenzi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAllTimeLocations().length ? sortedAllTimeLocations().map((location: any, index: number) => (
                  <TableRow key={index} className='table-body-row'>
                    <TableCell>
                      {location.name}
                    </TableCell>
                    <TableCell>
                      {location.quantity}
                    </TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell align='center' colSpan={2}>
                      Nu s-au inregistrat comenzi in tot timpul
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </TabContext>
      <LocationsMonthStatsModal isOpen={isOpenMonth} setIsOpen={setIsOpenMonth} data={monthValues[selectedMonth].locations} selectedMonth={selectedMonth} />
      <LocationsYearStatsModal isOpen={isOpenYear} setIsOpen={setIsOpenYear} data={props.stats.yearly} selectedYear={selectedYear} />
    </div>
  )
}

export default LocationsStatsCard