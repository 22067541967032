import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, FormHelperText, Grid, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { editInfo } from '../../../../api/general-info'
import { Store } from 'react-notifications-component'
import { serverUrl } from '../../../../request'
import BannerUpload from './BannerUpload/BannerUpload'
import './GeneralPage.scss'

type Props = {}

const c = 'general-page'

const GeneralPage = (props: Props) => {
  const generalInfo = localStorage.getItem('info') && JSON.parse(localStorage.getItem('info') || '')
  const {t} = useTranslation()
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState(generalInfo?.images.map((image: string) => serverUrl + image))
  const {register, getValues, handleSubmit, setValue, formState: {errors}} = useForm()

  function submit(){
    setLoading(true)
    const formData = new FormData()

    Object.entries(getValues()).forEach((item: any)=>{
      formData.append(item[0], item[1])
    })

    if(files.length){
      files.forEach((file: any) => {
        formData.append('images', file)
      })
    }

    editInfo(formData).then((res)=>{
      const params = {...getValues(), images: res.data.images.map((image: string) => serverUrl + image )}

      localStorage.setItem('info', JSON.stringify(params))
      setImages(params.images)

      Store.addNotification({
        title: 'SUCCESS',
        message: 'Success',
        container: 'top-center',
        type: "success",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    })
      .catch(()=>{
        Store.addNotification({
          title: 'ERROR',
          message: 'Error',
          container: 'top-center',
          type: "danger",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(generalInfo){
      setValue('phone1', generalInfo.phone1)
      setValue('phone2', generalInfo.phone2)
      setValue('minTime', generalInfo.minTime)
      setValue('maxTime', generalInfo.maxTime)
      setValue('minPrice', generalInfo.minPrice)
      setValue('dayOff', generalInfo.dayOff)
      setValue('announcement', generalInfo.announcement)
    }
  },[])

  return (
    <form onSubmit={handleSubmit(submit)} className={c}>
      <div className={`${c}__title`}>General</div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('phone1', {required: true})}
            placeholder='Telefon 1'
            error={!!errors.phone1}
            fullWidth
            variant='outlined'
            size='small'
            inputProps={{
              style: {
                borderColor: 'red'
              }
            }}
          />
          {errors?.phone1 && <FormHelperText error={!!errors?.phone1} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('phone2', {required: true})}
            placeholder='Telefon 2'
            error={!!errors.phone2}
            fullWidth
            variant='outlined'
            size='small'
            inputProps={{
              style: {
                borderColor: 'red'
              }
            }}
          />
          {errors?.phone2 && <FormHelperText error={!!errors?.phone2} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('minTime', {required: true})}
            placeholder='Livrarea de la:'
            error={!!errors.minTime}
            fullWidth
            variant='outlined'
            size='small'
            inputProps={{
              style: {
                borderColor: 'red'
              }
            }}
          />
          {errors?.minTime && <FormHelperText error={!!errors?.minTime} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('maxTime', {required: true})}
            placeholder='Livrarea pana la:'
            error={!!errors.maxTime}
            fullWidth
            variant='outlined'
            size='small'
            inputProps={{
              style: {
                borderColor: 'red'
              }
            }}
          />
          {errors?.maxTime && <FormHelperText error={!!errors?.maxTime} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('minPrice', {required: true})}
            placeholder='Pretul minim'
            error={!!errors.minPrice}
            fullWidth
            variant='outlined'
            size='small'
            inputProps={{
              style: {
                borderColor: 'red'
              }
            }}
          />
          {errors?.minPrice && <FormHelperText error={!!errors?.minPrice} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('dayOff')}
            placeholder='Zile Libere'
            multiline
            rows={3}
            fullWidth
            variant='outlined'
            size='small'
            inputProps={{
              style: {
                borderColor: 'red'
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('announcement')}
            placeholder='Anunt'
            multiline
            rows={3}
            fullWidth
            variant='outlined'
            size='small'
            inputProps={{
              style: {
                borderColor: 'red'
              }
            }}
          />
        </Grid>
      </Grid>
      <div className={`${c}__banner`}>
        <BannerUpload files={files} setFiles={setFiles} setImages={setImages} images={images}/>
      </div>
      <div className={`${c}__submit`}>
        <Button
          variant='contained'
          type='submit'
          disabled={loading}
          sx={{
            backgroundColor: '#ff1647',
            textTransform: 'none',
            fontWeight: 'bold',
            width: '100%',
            '&:hover': { backgroundColor: '#cc1138' },
          }}
        >
          {loading ? 
            <CircularProgress
              size="2rem"
              thickness={5}
              sx={{color: '#ff1647'}}
            /> 
            : 'Salveaza'}
        </Button>
      </div>
    </form>
  )
}

export default GeneralPage