import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { IProduct } from '../../types/products'
import CartPageItem from './cart-page-item/CartPageItem'
import { Button } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useDeviceDetect } from '../../utils/use-device-detect'
import CartPageItemMobile from './cart-page-item/cart-page-item-mobile/CartPageItemMobile'
import { getProducts } from '../../api/products'
import MenuItem from '../../components/menu-item/MenuItem'
import { ReactComponent as Loading } from '../../assets/icons/loading.svg'
import './CartPage.scss'
import CartMenuItem from '../../components/cart-menu-item/CartMenuItem'

const c = 'cart-page'

const CartPage = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {cart, setCart} = useOutletContext<any>()
  const [additionalProducts, setAdditionalProducts] = useState([])
  const [loadingProducts, setLoadingProducts] = useState(false)
  const {isMobile} = useDeviceDetect()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const generalInfo = JSON.parse(localStorage.getItem('info') || '')

  function getProductsData() {
    setLoadingProducts(true)
    getProducts()
      .then((res) => {
        const filtredProducts = res.data?.map((item: any) => item.items.filter((product: IProduct) => product.isAdditional)).flat()

        setAdditionalProducts(filtredProducts)
      })
      .finally(() => {
        setLoadingProducts(false)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    const targetTimezone = 'Europe/Chisinau';
    const targetTimeBefore = DateTime.now().setZone(targetTimezone).set({ hour: Number(generalInfo?.minTime.split(':')[0] || 10), minute: Number(generalInfo?.minTime.split(':')[1] || 0), second: 0 });
    const targetTimeAfter = DateTime.now().setZone(targetTimezone).set({ hour: Number(generalInfo?.maxTime.split(':')[0] || 23), minute: Number(generalInfo?.maxTime.split(':')[1] || 0), second: 0 });

    const currentTime = DateTime.now().setZone(targetTimezone);

    const isBefore10AM = currentTime < targetTimeBefore;
    const isAfter11PM = currentTime > targetTimeAfter;

    setIsButtonDisabled(isBefore10AM || isAfter11PM);
  }, []);

  function disableCart(){
    if(generalInfo.dayOff){
      return true
    }

    if(isButtonDisabled){
      return true
    }

    if(!cart.length){
      return true
    }

    if(Number(totalPrice) < Number(generalInfo?.minPrice)){
      return true
    }

    return false
  }

  const totalPrice = cart.reduce((total: number, item: IProduct) => total + (Number(item.price) * item.quantity!), 0);

  useEffect(()=>{
    scrollTo({top: 0, behavior: 'smooth'})
  },[])

  useEffect(()=>{
    getProductsData()
  },[])

  const updatedAdditionalProducts = additionalProducts.filter((additionalProduct: any) => {
    const existsInCart = cart.some((cartItem: any) => cartItem._id === additionalProduct._id);
  
    if (existsInCart) {
      return false; 
    } else {
      return true;
    }
  });

  return (
    <div className={c}>
      <div className={`${c}__container`}>
        <div className={`${c}__container-title`}>{t('CART')}</div>
        <div className={`${c}__container-products`}>
          {cart.length ? cart.map((item: IProduct) => (
            isMobile ? <CartPageItemMobile data={item} key={item._id} setCart={setCart} /> : <CartPageItem data={item} key={item._id} setCart={setCart} />
          )) : (
            <div className={`${c}__container-products-no_data`}>
              {t('CART_IS_EMPTY')}
            </div>
          )}
        </div>
        {!!cart.length && (
          <React.Fragment>
            {loadingProducts ? <Loading /> : (
              updatedAdditionalProducts.length ?
                <div className={`${c}__container-additions`}>
                  <div className={`${c}__container-additions-title`}>
                    {t('SOMETHING_ELSE')}
                  </div>
                  <div className={`${c}__container-additions-content`}>
                    {updatedAdditionalProducts.map((product: IProduct) => {
                      return (
                        <div key={product._id} className={`${c}__container-additions-content-item`}>
                          <CartMenuItem data={product} cart={cart} setCart={setCart}/>
                        </div>
                      )
                    })}
                  </div> 
                </div>
                : null
            )}
            <div className={`${c}__container-footer`}>
              <div className={`${c}__container-footer-total`}>
                <div className={`${c}__container-footer-total-item`}>
                  <div className={`${c}__container-footer-total-item-label`}>{t("ORDER_SUM")}</div>
                  <div className={`${c}__container-footer-total-item-value`}>{totalPrice} lei</div>
                </div>
                <div className={`${c}__container-footer-total-checkout`}>
                  <Button
                    variant='contained'
                    disabled={disableCart()}
                    onClick={() => navigate('checkout')}
                    sx={{
                      backgroundColor: '#ff1647',
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      width: '100%',
                      '&:hover': { backgroundColor: '#cc1138' },
                    }}
                  >
                    {t('FINALIZE_ORDER')}
                  </Button>
                </div>
                {Number(totalPrice) < Number(generalInfo?.minPrice) && <div className={`${c}-note`}><Trans i18nKey='MINIMUM_PRICE' values={{minPrice: generalInfo?.minPrice}}/></div>}
                {(!generalInfo?.dayOff && isButtonDisabled) && <div className={`${c}-note-time_limit`}><Trans i18nKey='TIME_LIMIT' values={{minTime: generalInfo?.minTime, maxTime: generalInfo.maxTime}}/></div>}
                {generalInfo?.dayOff && <div className={`${c}-note-time_limit`}><Trans i18nKey='DAY_OFF'/></div>}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default CartPage