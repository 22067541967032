import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './CategoriesSlider.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { useSearchParams } from 'react-router-dom'
import { useDeviceDetect } from '../../utils/use-device-detect'
import i18next from 'i18next'
import { ICategory } from '../../types/categories'
import { useTranslation } from 'react-i18next'

type Props = {
  data: ICategory[]
}

const c = 'categories-slider'

const CategoriesSlider = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {isMobile} = useDeviceDetect()
  const { t, i18n } = useTranslation();


  const getTranslatedName = (item: ICategory) => {
    if (i18n.language === 'ru' && item?.ru_name) {
      return item.ru_name;
    }

    return item.name;
  };


  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
    <FontAwesomeIcon
      icon={faChevronLeft}
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden='true'
      aria-disabled={currentSlide === 0 ? true : false}
      type='button'
    />
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <FontAwesomeIcon
      icon={faChevronRight}
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden='true'
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type='button'
    />
  )

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 2 : props.data.length > 4 ? 4 : props.data.length,
    swipeToSlide: true,
    centerMode: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  }

  function handleClick(category_id: string) {
    if(searchParams.get('category_id') === category_id){
      searchParams.delete('category_id')
      setSearchParams(searchParams)
    } else{
      setSearchParams({ category_id })
    }
  }

  return (
    <div className={c}>
      {isMobile ?
        <div className={`${c}__categories`}>
          {props?.data?.map((item) => (
            <div
              key={item._id}
              className={`${c}__categories-item${
                searchParams.get('category_id') === item._id ? ' active' : ''
              }`}
              onClick={() => handleClick(item._id)}
            >
              {getTranslatedName(item)}
            </div>
          ))}
        </div>
        :
        <Slider {...settings}>
          {props?.data?.map((item) => (
            <div
              key={item._id}
              className={`slider-item${
                searchParams.get('category_id') === item._id ? ' active' : ''
              }`}
              onClick={() => handleClick(item._id)}
            >
              {getTranslatedName(item)}
            </div>
          ))}
        </Slider>
      }
    </div>
  )
}

export default CategoriesSlider
