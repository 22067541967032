import React from 'react'
import { Button } from '@mui/material'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { IOrder } from '../../../../../types/orders'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    handleDelete: (orderId: string) => void
    order?: IOrder
}

const c = 'orders-modal'

const DeleteConfirmationModal = (props: Props) => {

  return (
    <ReactModal ariaHideApp={false} className='orders-modal-container delete-confirmation' isOpen={props.isOpen}>
      <div className={c}>
        <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div>
        <div className={`${c}-title`}>ESTI SIGUR?</div>
        <div className={`${c}__content`}>
          
          <div className={`${c}__content-actions`}>
            <Button
              variant='contained'
              type='submit'
              onClick={() => props.setIsOpen(false)}
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                width: '100%',
                '&:hover': { backgroundColor: '#cc1138' },
              }}
            >
            Nu
            </Button>
            <Button
              variant='contained'
              type='submit'
              onClick={() => props.handleDelete(String(props.order?._id))}
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                width: '100%',
                '&:hover': { backgroundColor: '#cc1138' },
              }}
            >
            Da
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default DeleteConfirmationModal