import { request } from '../request'
import { IProductsParams } from '../types/products'

export const getProducts = (params?: IProductsParams) => {
  return request.get('/products', { params })
}

export const createProduct = (params: FormData) => {
  return request.post('/products', params)
}

export const getProductById = (id: string) => {
  return request.get(`/products/${id}`)
}

export const deleteProduct = (id: string) => {
  return request.delete(`/products/${id}`)
}

export const editProduct = (params: FormData, id: string) => {
  return request.put(`/products/${id}`, params)
}
