import React from 'react'
import './QuantityInput.scss'

type Props = {
  setQuantity: (quantity: any) => void
  quantity: number
  cartItem?: boolean
}

const c = 'quantity-input'

const QuantityInput = (props: Props) => {
  function increment(e: any) {
    props.setQuantity((prev: number) => {
      if(e.shiftKey){
        return prev + 10
      }
 
      return prev + 1
    })
  }

  function decrement(e: any) {
    if (props.quantity < 1 && !props.cartItem) {
      return
    }

    props.setQuantity((prev: number) => {
      if(e.shiftKey){
        if(prev - 10 < 0){
          return 0
        }

        return prev - 10
      }
 
      return prev - 1
    })
  }

  return (
    <div className={c}>
      <div className={`${c}__action`} onClick={decrement}>
        -
      </div>
      <div className={`${c}__value`}>{props.quantity}</div>
      <div className={`${c}__action`} onClick={increment}>
        +
      </div>
    </div>
  )
}

export default QuantityInput
