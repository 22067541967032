import React from 'react'
import InfoSection from '../../components/info-section/InfoSection'
import { Outlet, useOutletContext } from 'react-router-dom'
import Cart from '../../components/cart/Cart'
import { useDeviceDetect } from '../../utils/use-device-detect'

type Props = {cart: any; setCart: any}

const SilderLayout = ({cart, setCart}: Props) => {
  const {searchValue} = useOutletContext<any>()
  const {isMobile, isTablet} = useDeviceDetect()

  return (
    <div className='silder-layout'>
      <div className='header-background'>
        <div className='header-background-image' />
        <div className='header-overlay' />
      </div>
      <div className='body-content'>
        <div className='info-section-container'>
          <InfoSection />
          <div className='menu-content'>
            <Outlet context={{ cart, setCart, searchValue }} />
          </div>
        </div>
        {!(isMobile || isTablet) && <Cart cart={cart} setCart={setCart} />}
      </div>
    </div>
  )
}

export default SilderLayout