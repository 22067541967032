import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faPhone, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg'
import Logo from '../../assets/images/logo_bg.png'

import { useNavigate } from 'react-router-dom'
import { INSTAGRAM_LINK } from '../../utils/constants'
import './Sidebar.scss'

type Props = {
    setIsOpen?: (isOpen: boolean) => void
    isOpen?: boolean
}
const c = 'sidebar-menu'

const Sidebar = (props: Props) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={`${c} ${props.isOpen ? 'open' : ''}`}>
      <div className={`${c}__close`}>
        <FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen?.(false)} />
      </div>
      <div className={`${c}__content`}>
        <div className={`${c}__content-logo`}>
          <img src={Logo} alt='logo'></img>
        </div>
        <div className={`${c}__content-links`}>
          <div className={`${c}__content-links-link`} onClick={()=> {
            navigate('/')
            props.setIsOpen?.(false)
          }}
          >{t('MENU')}</div>
          <div className={`${c}__content-links-link`} onClick={() => {
            props.setIsOpen?.(false)
            navigate('/delivery-conditions')
          }}
          >{t('DELIVERY_CONDITIONS')}</div>
        </div>
        <div className={`${c}__content-info`}>
          <div className={`${c}__content-info-contact`}>
            <div className={`${c}__content-info-contact-icon`}>
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className={`${c}__content-info-contact-item`}>
              <div className={`${c}__content-info-contact-item-label`}>
                {t('CONTACT_US')}
              </div>
              <div className={`${c}__content-info-contact-item-value`}>
              069 388 892 | 068 247 847
              </div>
            </div>
          </div>
          <div className={`${c}__content-info-socials`}>
            <div className={`${c}__content-info-socials-icon`}>
              <FontAwesomeIcon icon={faShareNodes} />
            </div>
            <div className={`${c}__content-info-socials-item`}>
              <div className={`${c}__content-info-socials-item-label`}>
                {t('SOCIALS')}
              </div>
              <div className={`${c}__content-info-socials-item-value`}>
                <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer" >
                  <InstagramIcon/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar