import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ICategory } from '../../../../types/categories';

type Props = {
    categories: ICategory[]
    handleDelete: (id: string) => void
    handleEdit: (category: ICategory) => void
}
const c = 'categories-page'

const DropComponent = (props: Props) => {
  const [enabled, setEnabled] = useState(false);
  
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
    
  if (!enabled) {
    return null;
  }

  return (
    <Droppable droppableId="categories">
      {(provided) => (
        <div className={`${c}__categories`}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {props.categories.map((category, index) => {
            return (
              <Draggable key={category._id} draggableId={category._id} index={index}>
                {(provided) => (
                  <div
                    key={category._id}
                    className={`${c}__categories-item`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className={`${c}__categories-item-title`}>{category.name}</div>
                    <div className={`${c}__categories-item-actions`}>
                      <div className={`${c}__categories-item-actions-btn`}> 
                        <Button
                          variant='contained'
                          onClick={() => props.handleEdit(category)}
                          sx={{
                            backgroundColor: '#ff1647',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            width: 'fit-content',
                            '&:hover': { backgroundColor: '#cc1138' },
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </div>
                      <div className={`${c}__categories-item-actions-btn`}> 
                        <Button
                          variant='contained'
                          onClick={() => props.handleDelete(category._id)}
                          sx={{
                            backgroundColor: '#ff1647',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            width: 'fit-content',
                            '&:hover': { backgroundColor: '#cc1138' },
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default DropComponent