import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { getIngredients } from '../../../../api/ingredients'
import { useForm } from 'react-hook-form'
import { Button, TextField } from '@mui/material'
import { editProduct, getProductById } from '../../../../api/products'
import { FOOD_SET_CATEGORY_ID, SUSHI_SET_CATEGORY_ID } from '../../../../utils/constants'
import { Store } from 'react-notifications-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import './ProductsCostPage.scss'

const c = 'products-cost-page'

const ProductsCostPage = () => {
  const {id} = useParams()
  const {register, getValues, watch, setValue} = useForm()
  const {products, setTotalPrice} = useOutletContext<any>()
  const [filteredProducts, setFilteredProducts] = useState<any[]>(products)
  const [isSet, setIsSet] = useState(false)
  const [product, setProduct] = useState<any>()
  const [ingredients, setIngredients] = useState<any>([])
  const [totalCost, setTotalCost] = useState(product?.total_cost)
  const containerRef = React.useRef<HTMLDivElement>(null)

  function fetchIngredients() {
    getIngredients()
      .then(res => {
        setIngredients(res.data)
      })
      .catch(err => console.log(err))
  }

  function handleSubmit(){

    const ingredientsData = isSet ? [

      ...filteredProducts.reduce((acc: any, product: any) => {
        return product.items.reduce((acc: any, item: any) => {
          if(!getValues(`selected.${item._id}`)) {return acc}
  
          return [
            ...acc,
            {
              ingredientId: item._id,
              quantity: getValues(`quantity.${item._id}`),
              unit: item.total_cost
            }
          ]
        }, acc)
      }, []),

      ...ingredients.map((ingredient: any) => {
        if(!getValues(`selected.${ingredient._id}`)) {return}
  
        return {
          ingredientId: ingredient._id,
          quantity: getValues(`quantity.${ingredient._id}`),
          unit: getValues(`price.${ingredient._id}`)
        }
      }).filter((ingredient: any) => ingredient)

    ]
      : ingredients.map((ingredient: any) => {
        if(!getValues(`selected.${ingredient._id}`)) {return}
  
        return {
          ingredientId: ingredient._id,
          quantity: getValues(`quantity.${ingredient._id}`),
          unit: getValues(`price.${ingredient._id}`)
        }
      }).filter((ingredient: any) => ingredient)

    const params = {
      ...product,
      ingredients: ingredientsData,
      total_cost: totalCost || 0,
    }

    editProduct(params, product._id).then(() => {
      Store.addNotification({
        title: 'SUCCESS',
        message: 'Success',
        container: 'top-center',
        type: "success",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    }).catch(() => {
      Store.addNotification({
        title: 'ERROR',
        message: 'Error',
        container: 'top-center',
        type: "danger",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    })
  }

  useEffect(() => {
    fetchIngredients()
  }, [])

  useEffect(() => {
    if(id){
      getProductById(id)
        .then((res) => {
          setProduct(res.data)
        })
        .catch((err) => console.log(err))
    }
  }, [id])

  useEffect(() => {
    if(product){
      ingredients.forEach((ingredient: any) => {
        const productIngredient = product.ingredients.find((productIngredient: any) => productIngredient.ingredientId === ingredient._id)

        if(productIngredient){
          setValue(`selected.${ingredient._id}`, true)
          setValue(`price.${ingredient._id}`, productIngredient.unit)
          setValue(`quantity.${ingredient._id}`, productIngredient.quantity)
        } else {
          setValue(`selected.${ingredient._id}`, false)
          setValue(`price.${ingredient._id}`, 'price_gram')
          setValue(`quantity.${ingredient._id}`, 0)
        }
      })

      filteredProducts.forEach((item: any) => {
        item.items.forEach((item: any) => {
          const productItem = product.ingredients.find((productIngredient: any) => productIngredient.ingredientId === item._id)

          if(productItem){
            setValue(`selected.${item._id}`, true)
            setValue(`quantity.${item._id}`, productItem.quantity)
          } else {
            setValue(`selected.${item._id}`, false)
            setValue(`quantity.${item._id}`, 0)
          }
        })
      })
    }
  }, [product, filteredProducts])

  useEffect(() => {
    let total = 0

    if (isSet) {
      // if product is a Set, calculate total cost based on selected filteredProducts AND ingredients
      total += filteredProducts.reduce((acc: number, product: any) => {
        return product.items.reduce((acc: number, item: any) => {
          if(!getValues(`selected.${item._id}`)) {return acc}

          return acc + item.total_cost * getValues(`quantity.${item._id}`)
        }, acc)
      }, 0)

      total += ingredients.reduce((acc: number, ingredient: any) => {
        if(!getValues(`selected.${ingredient._id}`)) {return acc}

        const price = getValues(`price.${ingredient._id}`) === 'price_piece' ? ingredient.price_piece : ingredient.price_gram
        const quantity = getValues(`quantity.${ingredient._id}`)

        return acc + price * quantity
      }, 0)
    } else {
      total = ingredients.reduce((acc: number, ingredient: any) => {
        if(!getValues(`selected.${ingredient._id}`)) {return acc}

        const price = getValues(`price.${ingredient._id}`) === 'price_piece' ? ingredient.price_piece : ingredient.price_gram
        const quantity = getValues(`quantity.${ingredient._id}`)

        return acc + price * quantity
      }, 0)
    }

    setTotalCost(total)
  }, [watch()])

  useEffect(() => {
    if(product){
      setTotalPrice({id: product?._id, total_cost: totalCost})
    }
  }, [totalCost])

  useEffect(()=>{
    setFilteredProducts(products.filter((product: any) => !(product.category_id === FOOD_SET_CATEGORY_ID || product.category_id === SUSHI_SET_CATEGORY_ID)))

  },[products])

  useEffect(()=>{
    if(product){
      setIsSet(product.category === FOOD_SET_CATEGORY_ID || product.category === SUSHI_SET_CATEGORY_ID)
    }
  },[product])

  return (
    <div className={c} ref={containerRef}>
      {product ? (
        <div className={`${c}__container`}>
          <div className={`${c}__container-header`}>
            <div className={`${c}__container-header-title`}>{product.name}</div>
            <div className={`${c}__container-header-cost`}>Cost: {totalCost ? `${Number(totalCost).toFixed(2)} lei` : '-'}</div>
          </div>
         
          <div className={`${c}__container-content`}>
            <div className={`${c}__container-submit`}>
              <Button
                variant='contained'
                type='submit'
                sx={{
                  backgroundColor: '#ff1647',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  width: '100%',
                  '&:hover': { backgroundColor: '#cc1138' },
                }}
                onClick={handleSubmit}
              >
            Salveaza
              </Button>
            </div>
            {isSet ? (
              <div className={`${c}__container-content-items`}>
                {filteredProducts?.map((item: any) => (
                  item.items.map((item: any) => (
                    <div key={item._id} className={`${c}__container-content-items-item`}>
                      <div className={`${c}__container-content-items-item-checkbox`}>
                        <input
                          type="checkbox"
                          id={item._id}
                          {...register(`selected.${item._id}`)}
                        />
                      </div>
                      <div className={`${c}__container-content-items-item-title`}>{item.name}</div>
                      <div className={`${c}__container-content-items-item-input`}>
                        <TextField
                          type="number"
                          id={`quantity_${item._id}`}
                          {...register(`quantity.${item._id}`)}
                        />
                      </div>
                      <div className={`${c}__container-content-items-item-total-cost`}>
                        {Number(item.total_cost * (getValues(`quantity.${item._id}`) || 0)).toFixed(2) || 0} lei
                      </div>
                    </div>
                  ))
                ))}
                {ingredients?.map((ingredient: any) => (
                  <div key={ingredient._id} className={`${c}__container-content-items-item`}>
                    <div className={`${c}__container-content-items-item-checkbox`}>
                      <input
                        type="checkbox"
                        id={ingredient._id}
                        {...register(`selected.${ingredient._id}`)}
                      />
                    </div>
                    <div className={`${c}__container-content-items-item-title`}>{ingredient.label}</div>
                    <div className={`${c}__container-content-items-item-radios`}>
                      <div>
                        <input
                          type="radio"
                          id={`price_gram_${ingredient._id}`}
                          value="price_gram"
                          defaultChecked
                          {...register(`price.${ingredient._id}`)}
                        />
                        <label htmlFor={`price_gram_${ingredient._id}`}>gram ({ingredient.price_gram} lei)</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id={`price_piece_${ingredient._id}`}
                          value="price_piece"
                          {...register(`price.${ingredient._id}`)}
                        />
                        <label htmlFor={`price_piece_${ingredient._id}`}>bucata ({ingredient.price_piece} lei)</label>
                      </div>
                    </div>
                    <div className={`${c}__container-content-items-item-input`}>
                      <TextField
                        type="number"
                        id={`quantity_${ingredient._id}`}
                        {...register(`quantity.${ingredient._id}`)}
                      />
                    </div>
                    <div className={`${c}__container-content-items-item-total-cost`}>
                      {Number((getValues(`price.${ingredient._id}`) === 'price_piece' ? ingredient.price_piece : ingredient.price_gram) * (getValues(`quantity.${ingredient._id}`) || 0)).toFixed(2)} lei
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={`${c}__container-content-items`}>
                {ingredients?.map((ingredient: any) => (
                  <div key={ingredient._id} className={`${c}__container-content-items-item`}>
                    <div className={`${c}__container-content-items-item-checkbox`}>
                      <input
                        type="checkbox"
                        id={ingredient._id}
                        {...register(`selected.${ingredient._id}`)}
                      />
                    </div>
                    <div className={`${c}__container-content-items-item-title`}>{ingredient.label}</div>
                    <div className={`${c}__container-content-items-item-radios`}>
                      <div>
                        <input
                          type="radio"
                          id={`price_gram_${ingredient._id}`}
                          value="price_gram"
                          defaultChecked
                          {...register(`price.${ingredient._id}`)}
                        />
                        <label htmlFor={`price_gram_${ingredient._id}`}>gram ({ingredient.price_gram} lei)</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id={`price_piece_${ingredient._id}`}
                          value="price_piece"
                          {...register(`price.${ingredient._id}`)}
                        />
                        <label htmlFor={`price_piece_${ingredient._id}`}>bucata ({ingredient.price_piece} lei)</label>
                      </div>
                    </div>
                    <div className={`${c}__container-content-items-item-input`}>
                      <TextField
                        type="number"
                        id={`quantity_${ingredient._id}`}
                        {...register(`quantity.${ingredient._id}`)}
                      />
                    </div>
                    <div className={`${c}__container-content-items-item-total-cost`}>
                      {Number((getValues(`price.${ingredient._id}`) === 'price_piece' ? ingredient.price_piece : ingredient.price_gram) * (getValues(`quantity.${ingredient._id}`) || 0)).toFixed(2)} lei
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={`${c}__container-up_button`}>
            <Button
              onClick={() => containerRef.current?.scrollTo({top: 0, behavior: 'smooth'})}
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                color: 'white',
                opacity: 0.3,
                '&:hover': { backgroundColor: '#cc1138', opacity: 1 },
              }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleUp}></FontAwesomeIcon>
            </Button>
          </div>
        </div>
      ) : ''}
    </div>
  )
}

export default ProductsCostPage