import React from 'react'
import Logo from '../../assets/images/logo_text.png'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './HeaderMenu.scss'

const c = 'header-menu'

const HeaderMenu = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className={c}>
      <div className={`${c}__container`}>
        <div className={`${c}__container-menu`}>
          <div className={`${c}__container-menu-logo`}>
            <img src={Logo} alt='logo' onClick={() => navigate('/')} />
          </div>
          <div className={`${c}__container-menu-links`}>
            <div className={`${c}__container-menu-links-link`} onClick={() => navigate('/')}>{t('MENU')}</div>
            <div className={`${c}__container-menu-links-link`} onClick={() => navigate('/delivery-conditions')}>
              {t('DELIVERY_CONDITIONS')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderMenu
