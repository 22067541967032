import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { faClose} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Store } from 'react-notifications-component'
import { editOrder } from '../../../../../api/order'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDeviceDetect } from '../../../../../utils/use-device-detect'
import './OrdersModal.scss'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    data?: any
    refetch?: any
}
const c = 'orders-modal'

const EditOrdersModal = (props: Props) => {
  const generalInfo = localStorage.getItem('info') && JSON.parse(localStorage.getItem('info') || '')
  const {t} = useTranslation()
  const {isMobile} = useDeviceDetect()
  const [date, setDate] = React.useState<Date | null>(new Date())
  const [time, setTime] = React.useState<Date | null>(new Date())

  function submit(){
    const params = {
      date: date?.toLocaleDateString(),
      time: time?.toLocaleTimeString(),
    }

    editOrder(params, props.data?._id).then(() => {
      Store.addNotification({
        title: 'SUCCESS',
        message: 'Success',
        container: 'top-center',
        type: "success",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
      props.refetch?.()
    }).catch(() => {
      Store.addNotification({
        title: 'ERROR',
        message: 'Error',
        container: 'top-center',
        type: "danger",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    }).finally(()=>{
      props.setIsOpen(false)
    })
    

  }

  return (
    <ReactModal ariaHideApp={false} className='orders-modal-container' isOpen={props.isOpen}>
      <div className={c}>
        <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div>
        <div className={`${c}-title`}>Comanda</div>
        <div className={`${c}__content`}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={`${c}__content-picker`}>
              <MobileDatePicker 
                views={['month', 'day']}
                value={date}
                timezone='Europe/Chisinau'
                onChange={(newValue) => setDate(newValue)}
                slotProps={{
                  textField: {variant: 'outlined', sx: {
                    outline: 'none',
                    '.Mui-focused': {
                      boxShadow: '#ff1647 0 0 0 2px',
                      background: '#fff',
                      outline: "#ff1647",
                      borderColor: 'none',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none !important'
                      }
                    },
                    '.MuiOutlinedInput-root:focus-visible': {
                      outline: 'none'
                    },
                    '.MuiOutlinedInput-root:focus': {
                      outline: 'none'
                    },
                  }
                  },
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#ff1647",
                      },      
                    },
                  },
                  actionBar: {
                    sx: {
                      '.MuiButton-root': {
                        color: '#ff1647'
                      }
                    }
                  },
                }}
              />
              <div className={`${c}__content-picker-clock`}>
                <DigitalClock
                  timeStep={15}
                  skipDisabled
                  shouldDisableTime={() => false}
                  timezone='Europe/Chisinau'
                  sx={{
                    '.MuiDigitalClock-item': {
                      transition: 'all 0.2s ease-in-out',
                      '&.Mui-selected': {
                        backgroundColor: '#ff1647',
                        '&:hover': {
                          backgroundColor: isMobile ? 'none' : '#cc1138'
                        },
                      },
                    },
                    '.MuiDigitalClock-item:hover': {
                      backgroundColor: isMobile ? 'none' : '#cc1138',
                      color: isMobile ? '#000000' : '#ffffff'
                    },
                  }}
                  minTime={new Date(0, 0, 0, Number(generalInfo?.minTime.split(':')[0]))}
                  maxTime={new Date(0, 0, 0, Number(generalInfo?.maxTime.split(':')[0]))}
                  ampm={false}
                  value={time}
                  onChange={(newValue) => {
                    setTime(newValue);
                  }}
                />
              </div>
            </div>
          </LocalizationProvider>
          <div className={`${c}__content-submit`}>
            <Button
              variant='contained'
              type='submit'
              onClick={submit}
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                width: '100%',
                '&:hover': { backgroundColor: '#cc1138' },
              }}
            >
            Salveaza
            </Button>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default EditOrdersModal