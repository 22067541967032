import React, { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import './StatsCard.scss'

type Props = {
    stats: any
}

const c = 'stats-card'

const DeliveryStatsCard = (props: Props) => {
  const date = new Date();
  const currentMonth = date.toLocaleString('en-US', { month: 'long' }).toLowerCase();
  const {t} = useTranslation()
  const [value, setValue] = useState('0');
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const months = Object.keys(props.stats.yearly?.[props.stats.yearly?.length - 1]?.months)
  const monthValues = props.stats.yearly?.[props.stats.yearly?.length - 1]?.months

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className={c}>
      <div className={`${c}__title`}>Livrare</div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList variant="fullWidth" onChange={handleChange} sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#ff1647',
            },
            '& .MuiButtonBase-root.MuiTab-root': {
              color: 'black',
              transition: 'color 0.2s ease-in-out',
              '&:hover': {
                color: '#cc1138',
              },
              '&.Mui-selected': {
                color: '#ff1647',
              },
              '&.Mui-disabled': {
                color: 'lightgray',
              },
            },}}
          >
            <Tab label="Azi" value="0" />
            <Tab label="Pe Luna" value="1" />
            <Tab label="pe An" value="2" />
            <Tab label="Tot timpul" value="3" />
          </TabList>
        </Box>
        <TabPanel sx={{padding: '24px 0'}} value="0" className={`${c}__content`}>
          <div className={`${c}__content-stats`}>
            <div className={`${c}__content-stats-stat`}>
              <div className={`${c}__content-stats-stat-label`}>
                    Pretul Livrarilor:
              </div>
              <div className={`${c}__content-stats-stat-value`}>
                {props.stats.today?.delivery_sales} lei
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="1" className={`${c}__content`}>
          <div className={`${c}__content-months`}>
            {months?.map((month: any, index: number) => (
              <div key={index} className={`${c}__content-months-month ${selectedMonth === month ? 'active' : ''}`} onClick={() => setSelectedMonth(month)}>
                {t(month.toUpperCase())}
              </div>
            ))}
          </div>
          <div className={`${c}__content-stats`}>
            <div className={`${c}__content-stats-stat`}>
              <div className={`${c}__content-stats-stat-label`}>
                    Pretul Livrarilor:
              </div>
              <div className={`${c}__content-stats-stat-value`}>
                {monthValues[selectedMonth]?.delivery_sales} lei
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="2" className={`${c}__content`}>
          {props.stats.yearly?.map((stat: any, index: number) => (
            <React.Fragment key={index}>
              <div className={`${c}__content-title`}>{stat.year}</div>
              <div className={`${c}__content-stats`}>
                <div className={`${c}__content-stats-stat`}>
                  <div className={`${c}__content-stats-stat-label`}>
                    Pretul Livrarilor:
                  </div>
                  <div className={`${c}__content-stats-stat-value`}>
                    {stat.delivery_sales} lei
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="3" className={`${c}__content`}>
          <div className={`${c}__content-stats`}>
            <div className={`${c}__content-stats-stat`}>
              <div className={`${c}__content-stats-stat-label`}>
                    Pretul Livrarilor:
              </div>
              <div className={`${c}__content-stats-stat-value`}>
                {props.stats.allTime?.delivery_sales} lei
              </div>
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  )
}

export default DeliveryStatsCard