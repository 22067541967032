import React, { useEffect, useState } from 'react'
import { faClose, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactModal from 'react-modal'
import { Button, Checkbox, FormControlLabel, FormHelperText, Grid, MenuItem, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { createProduct, editProduct } from '../../../../../api/products'
import { Store } from 'react-notifications-component'
import './ProductsModal.scss'
import { serverUrl } from '../../../../../request'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    categories?: any
    data?: any
    refetch?: any
    edit?: boolean
}
const c = 'products-modal'

const ProductsModal = (props: Props) => {
  const {t} = useTranslation()
  const [files, setFiles] = useState([]);
  const [imageError, setImageError] = useState(false)
  const {register, getValues, handleSubmit, setValue, formState: {errors}} = useForm()

  const {getRootProps, open, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setImageError(false)
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })) as any);  
    },
    noClick: true,
    noKeyboard: true
  });

  function submit(){
    if(!files[0] && !props.data?.image){
      setImageError(true)

      return
    }

    const formData = new FormData()

    formData.append('name', getValues()?.name)
    formData.append('ru_name', getValues()?.ru_name)
    formData.append('price', getValues()?.price)
    formData.append('description', getValues()?.description)
    formData.append('ru_description', getValues()?.ru_description)
    formData.append('weight', getValues()?.weight)
    formData.append('category', getValues()?.category)
    formData.append('isAdditional', getValues()?.isAdditional)
    formData.append('pieces', getValues()?.pieces)
    formData.append('isNew', getValues()?.isNew)
    formData.append('discount', getValues()?.discount)

    if(files[0]){
      formData.append('image', files[0])
    }

    if(props.data){
      editProduct(formData, props.data?._id).then(() => {
        Store.addNotification({
          title: 'SUCCESS',
          message: 'Success',
          container: 'top-center',
          type: "success",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
        props.refetch?.()
      }).catch(() => {
        Store.addNotification({
          title: 'ERROR',
          message: 'Error',
          container: 'top-center',
          type: "danger",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
      }).finally(()=>{
        props.setIsOpen(false)
      })
    } else {
      createProduct(formData).then(() => {
        Store.addNotification({
          title: 'SUCCESS',
          message: 'Success',
          container: 'top-center',
          type: "success",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
        props.refetch?.()
      }).catch(() => {
        Store.addNotification({
          title: 'ERROR',
          message: 'Error',
          container: 'top-center',
          type: "danger",
          insert: "top",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true
          }
        })
      }).finally(()=>{
        props.setIsOpen(false)
      })
    }

  }

  useEffect(()=>{
    if(props.data){
      setValue('name', props.data.name)
      setValue('ru_name', props.data.ru_name)
      setValue('price', props.data.price)
      setValue('weight', props.data.weight)
      setValue('pieces', props.data.pieces)
      setValue('description', props.data.description)
      setValue('ru_description', props.data.ru_description)
      setValue('isAdditional', props.data.isAdditional)
      setValue('isNew', props.data.isNew)
      setValue('discount', props.data.discount)
    }
  },[props.data, props.categories])

  return (
    <ReactModal className='products-modal-container' isOpen={props.isOpen}>
      <div className={c}>
        <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div>
        <div className={`${c}-title`}>Produs</div>
        <form onSubmit={handleSubmit(submit)} className={`${c}__content`}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={10}>
              <TextField
                {...register('name', {required: true})}
                label='Nume'
                error={!!errors.name}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
              {errors?.name && <FormHelperText error={!!errors?.name} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                {...register('price', {required: true})}
                label='Pret'
                error={!!errors.price}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
              {errors?.price && <FormHelperText error={!!errors?.price} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                {...register('ru_name', {required: true})}
                label='Nume (ru)'
                error={!!errors.ru_name}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
              {errors?.ru_name && <FormHelperText error={!!errors?.ru_name} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                select
                {...register('category', {required:true})}
                label='Categorie'
                defaultValue={props.categories.find((item: any) => item._id === props.data?.category)?._id}
                error={!!errors.category}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              >
                {props.categories?.map((category: any) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))}
              </TextField>
              {errors?.category && <FormHelperText error={!!errors?.category} id="component-error-text">{t('FIELD_REQUIRED')}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                {...register('weight')}
                label='Gramajul'
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                {...register('pieces')}
                label='Cantitatea'
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                {...register('discount')}
                label='Reducere'
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('description')}
                label='Descriere'
                multiline
                rows={3}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('ru_description')}
                label='Descriere (ru)'
                multiline
                rows={3}
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel 
                control={
                  <Checkbox
                    {...register('isAdditional')}
                    size='small'
                    defaultChecked={props.data?.isAdditional}
                    inputProps={{
                      style: {
                        borderColor: 'red'
                      }
                    }}
                  />
                } 
                label="Aditional?"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel 
                control={
                  <Checkbox
                    {...register('isNew')}
                    defaultChecked={props.data?.isNew}
                    size='small'
                    inputProps={{
                      style: {
                        borderColor: 'red'
                      }
                    }}
                  />
                } 
                label="Nou?"
              />
            </Grid>
            <Grid item xs={12}>
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} {...register('image')} />
                {!files.length &&
                <div className={`${c}__content-image ${imageError ? 'image-error' : ''}`}>
                  <div className={`${c}__content-image-preview`}>
                    {props.data?.image ?
                      <img src={serverUrl + props.data?.image}></img>
                      : 
                      <FontAwesomeIcon className='upload-icon' icon={faUpload} /> 
                    }
                    <Button
                      variant='contained'
                      onClick={open}
                      sx={{
                        backgroundColor: '#ff1647',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        width: '100%',
                        '&:hover': { backgroundColor: '#cc1138' },
                      }}
                    >
                         Incarca
                    </Button>
                  </div>
                </div>
                }
                {files.map((file: any, index) => (
                  <div key={index} className={`${c}__content-image`}>
                    <div className={`${c}__content-image-preview`}>
                      <img src={file.preview}></img>
                      <Button
                        variant='contained'
                        onClick={open}
                        sx={{
                          backgroundColor: '#ff1647',
                          textTransform: 'none',
                          fontWeight: 'bold',
                          width: '100%',
                          '&:hover': { backgroundColor: '#cc1138' },
                        }}
                      >
                            Schimba
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
          <div className={`${c}__content-submit`}>
            <Button
              variant='contained'
              type='submit'
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                width: '100%',
                '&:hover': { backgroundColor: '#cc1138' },
              }}
            >
            Salveaza
            </Button>
          </div>
        </form>
      </div>
    </ReactModal>
  )
}

export default ProductsModal