import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './DeliveryConditionsPage.scss'
import { getLocations } from '../../api/locations'

type Props = {}
const c = 'delivery-conditions-page'

const DeliveryConditionsPage = (props: Props) => {
  const generalInfo = JSON.parse(localStorage.getItem('info') || '')
  const {t, i18n} = useTranslation()
  const [locations, setLocations] = useState([])

  const getTranslatedName = (item: any) => {
    if (i18n.language === 'ru' && item?.label_ru) {
      return item.label_ru;
    }

    return item.label;
  };

  function getLocationsData(){
    getLocations().then((res) => {
      setLocations(res.data)
    })
  }

  useEffect(() => {
    window.scrollTo({top: 700, behavior: 'smooth'})
    getLocationsData()
  }, [])

  return (
    <div className={c}>
      <div className={`${c}__schedule`}>
        <Trans i18nKey='DELIVERY_SCHEDULE' values={{minTime: generalInfo?.minTime, maxTime: generalInfo?.maxTime}} />
      </div>
      <div className={`${c}__subtitle`}>{t('LOCATION_PRICE')}:</div>
      <div className={`${c}__locations`}>
        {locations?.map((location: any) => (
          <div key={location._id} className={`${c}__locations-location`}>
            <div className={`${c}__locations-location-label`}>{getTranslatedName(location)}</div>
            -
            <div className={`${c}__locations-location-value`}>{location.delivery_price} lei</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DeliveryConditionsPage