import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import CartItem from './CartItem/CartItem'
import { IProduct } from '../../types/products'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { DateTime } from 'luxon';
import './Cart.scss'

type Props = {
  cart?: any
  setCart: any
}
const c = 'cart-card'

const Cart = (props: Props) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const generalInfo = JSON.parse(localStorage.getItem('info') || '')

  useEffect(() => {
    const targetTimezone = 'Europe/Chisinau';
    const targetTimeBefore = DateTime.now().setZone(targetTimezone).set({ hour: Number(generalInfo?.minTime.split(':')[0] || 10), minute: Number(generalInfo?.minTime.split(':')[1] || 0), second: 0 });
    const targetTimeAfter = DateTime.now().setZone(targetTimezone).set({ hour: Number(generalInfo?.maxTime.split(':')[0] || 23), minute: Number(generalInfo?.maxTime.split(':')[1] || 0), second: 0 });

    const currentTime = DateTime.now().setZone(targetTimezone);

    const isBefore10AM = currentTime < targetTimeBefore;
    const isAfter11PM = currentTime > targetTimeAfter;

    setIsButtonDisabled(isBefore10AM || isAfter11PM);
  }, []);

  const totalPrice = props.cart.reduce(
    (acc: number, item: any) =>
      Number(acc) + Number(item?.quantity) * item.price,
    0
  )

  function disableCart(){
    if(generalInfo.dayOff){
      return true
    }

    if(isButtonDisabled){
      return true
    }

    if(!props.cart.length){
      return true
    }

    if(Number(totalPrice) < Number(generalInfo?.minPrice)){
      return true
    }

    return false
  }

  return (
    <div className={c}>
      <div className={`${c}__data`}>
        {props.cart?.length ? (
          props.cart?.map((item: IProduct) => (
            <CartItem key={item._id} data={item} setCart={props.setCart} />
          ))
        ) : (
          <div className={`${c}-no_data`}>{t('NO_PRODUCT_SELECTED')}</div>
        )}
      </div>
      <div className={`${c}-divider`} />
      <div className={`${c}-total`}>
        <div className={`${c}-total-label`}>{t('TOTAL')}:</div>
        <div className={`${c}-total-value`}>
          {totalPrice} lei
        </div>
      </div>
      {Number(totalPrice) < Number(generalInfo?.minPrice) && <div className={`${c}-note`}><Trans i18nKey='MINIMUM_PRICE' values={{minPrice: generalInfo?.minPrice}}/></div>}
      <div className={`${c}-add_to_cart`}>
        <Button
          variant='contained'
          disabled={disableCart()}
          onClick={()=> navigate('/cart')}
          sx={{
            backgroundColor: '#ff1647',
            textTransform: 'none',
            fontWeight: 'bold',
            width: '100%',
            '&:hover': { backgroundColor: '#cc1138' },
          }}
        >
          {t('CART')}
        </Button>
      </div>
      {(isButtonDisabled && !generalInfo.dayOff) && <div className={`${c}-note-time_limit`}><Trans i18nKey='TIME_LIMIT' values={{minTime: generalInfo?.minTime, maxTime: generalInfo.maxTime}}/></div>}
      {generalInfo?.dayOff && <div className={`${c}-note-time_limit`}><Trans i18nKey='DAY_OFF'/></div>}
    </div>
  )
}

export default Cart
