import React, { useEffect } from 'react'
import { faClose} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactModal from 'react-modal'
import { Button, Grid, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import './OrdersModal.scss'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    product?: any
    setProduct?: any
    productsRef: any
}
const c = 'orders-modal'

const QuantityModal = (props: Props) => {
  const {register, getValues, handleSubmit} = useForm()

  function submit(){
    const params = {
      quantity: Number(getValues().quantity),
    }

    props.setProduct({...props.product, ...params})
    props.setIsOpen(false)

    if (props.productsRef.current) {
      props.productsRef.current.focus();
    }
  }

  return (
    <ReactModal className='orders-modal-container' isOpen={props.isOpen}>
      <div className={c}>
        {/* <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div> */}
        <div className={`${c}-title`}>Cantitate</div>
        <form onSubmit={handleSubmit(submit)} className={`${c}__content`}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type='number'
                {...register('quantity', {required: true})}
                label='Cantitate'
                fullWidth
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              />
            </Grid>
          </Grid>
          <div className={`${c}__content-submit`}>
            <Button
              variant='contained'
              type='submit'
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                width: '100%',
                '&:hover': { backgroundColor: '#cc1138' },
              }}
            >
            Salveaza
            </Button>
          </div>
        </form>
      </div>
    </ReactModal>
  )
}

export default QuantityModal