import React, { useEffect, useRef, useState } from 'react'
import { faClose} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactModal from 'react-modal'
import { Autocomplete, Button, Chip, Grid, MenuItem, Select, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { Store } from 'react-notifications-component'
import { createOrder } from '../../../../../api/order'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDeviceDetect } from '../../../../../utils/use-device-detect'
import { getLocations } from '../../../../../api/locations'
import { getProducts } from '../../../../../api/products'
import QuantityModal from './QuantityModal'
import { IProduct } from '../../../../../types/products'
import './OrdersModal.scss'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    refetch?: any
}
const c = 'orders-modal'

const CreateOrderModal = (props: Props) => {
  const generalInfo = localStorage.getItem('info') && JSON.parse(localStorage.getItem('info') || '')
  const {isMobile} = useDeviceDetect()
  const {register, control, getValues} = useForm();
  const productsRef = useRef<HTMLInputElement | null>(null);

  const [date, setDate] = useState<Date | null>(new Date())
  const [time, setTime] = useState<Date | null>(new Date())
  
  const [locations, setLocations] = useState<any[]>([])
  const [products, setProducts] = useState<IProduct[]>([]);
  const [product, setProduct] = useState<IProduct>();
  const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
  const [openQuantity, setOpenQuantity] = useState(false)

  const handleChange = (_: any, newValue: any, reason: string) => {
    if(reason === 'selectOption'){
      setProduct(newValue[newValue.length - 1]);
      setOpenQuantity(true);
    }
    
    setSelectedProducts(newValue);
  };

  function submit(){
    const totalPrice = selectedProducts.reduce((total: number, item: IProduct) => total + (Number(item.price) * item.quantity!), 0);
    const formattedDate = date?.toLocaleDateString('en-GB');

    const params = {
      name: getValues().name,
      phone: '123123123',
      location: getValues().location?.label,
      details: 'test',
      delivery_method: 'Pe Loc',
      payment: 'Card',
      date: formattedDate,
      time: time?.toLocaleTimeString(),
      delivery_cost: getValues().location.label === 'Pe Loc' ? 0 : getValues().location?.delivery_price,
      order_price: totalPrice,
      total_price: totalPrice + (getValues().location.label === 'Pe Loc' ? 0 : getValues().location?.delivery_price),
      cart: selectedProducts
    }

    createOrder(params).then(() => {
      Store.addNotification({
        title: 'SUCCESS',
        message: 'Success',
        container: 'top-center',
        type: "success",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
      props.refetch?.()
    }).catch(() => {
      Store.addNotification({
        title: 'ERROR',
        message: 'Error',
        container: 'top-center',
        type: "danger",
        insert: "top",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      })
    }).finally(()=>{
      props.setIsOpen(false)
    })
  }

  function fetchLocations(){
    getLocations().then((res) => {
      setLocations([{_id: 'pe_loc', label: 'Pe Loc'},...res.data])
    })
  }

  function getProductsData() {
    getProducts()
      .then((res) => {
        const allItems = res.data.flatMap((category: any) => category.items);

        setProducts(allItems);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    fetchLocations()
    getProductsData()
  }, [])

  useEffect(() => {
    if (product?.quantity) {
      setSelectedProducts((prev: IProduct[]) => {
        const newProducts : IProduct[] = prev.filter((p: IProduct) => p._id !== product._id);

        newProducts.push(product);

        return newProducts;
      });
    }
  }, [product]);

  return (
    <ReactModal ariaHideApp={false} className='orders-modal-container' isOpen={props.isOpen}>
      <div className={c}>
        <div className={`${c}-close`}><FontAwesomeIcon icon={faClose} onClick={() => props.setIsOpen(false)}/></div>
        <div className={`${c}-title`}>Comanda</div>
        <div className={`${c}__content`}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...register('name')}
                fullWidth
                label='Nume'
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              >
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                {...register('location')}
                fullWidth
                label='Locatie'
                variant='outlined'
                size='small'
                inputProps={{
                  style: {
                    borderColor: 'red'
                  }
                }}
              >
                {locations?.map((location: any) => (
                  <MenuItem key={location._id} value={location}>
                    {location.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="products"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    options={products}
                    getOptionLabel={(option) => option.name}
                    value={selectedProducts}
                    onChange={handleChange}
                    filterSelectedOptions={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Produse"
                        inputRef={productsRef}
                        variant="outlined"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            borderColor: 'red',
                          },
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          label={option.name}
                          key={index}
                        />
                      ))
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={`${c}__content-picker`}>
              <MobileDatePicker 
                views={['month', 'day']}
                value={date}
                timezone='Europe/Chisinau'
                format='dd/MM/yyyy'
                onChange={(newValue) => setDate(newValue)}
                slotProps={{
                  textField: {variant: 'outlined', sx: {
                    outline: 'none',
                    '.Mui-focused': {
                      boxShadow: '#ff1647 0 0 0 2px',
                      background: '#fff',
                      outline: "#ff1647",
                      borderColor: 'none',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none !important'
                      }
                    },
                    '.MuiOutlinedInput-root:focus-visible': {
                      outline: 'none'
                    },
                    '.MuiOutlinedInput-root:focus': {
                      outline: 'none'
                    },
                  }
                  },
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#ff1647",
                      },      
                    },
                  },
                  actionBar: {
                    sx: {
                      '.MuiButton-root': {
                        color: '#ff1647'
                      }
                    }
                  },
                }}
              />
              <div className={`${c}__content-picker-clock`}>
                <DigitalClock
                  timeStep={15}
                  skipDisabled
                  shouldDisableTime={() => false}
                  timezone='Europe/Chisinau'
                  sx={{
                    '.MuiDigitalClock-item': {
                      transition: 'all 0.2s ease-in-out',
                      '&.Mui-selected': {
                        backgroundColor: '#ff1647',
                        '&:hover': {
                          backgroundColor: isMobile ? 'none' : '#cc1138'
                        },
                      },
                    },
                    '.MuiDigitalClock-item:hover': {
                      backgroundColor: isMobile ? 'none' : '#cc1138',
                      color: isMobile ? '#000000' : '#ffffff'
                    },
                  }}
                  minTime={new Date(0, 0, 0, Number(generalInfo?.minTime.split(':')[0]))}
                  maxTime={new Date(0, 0, 0, Number(generalInfo?.maxTime.split(':')[0]))}
                  ampm={false}
                  value={time}
                  onChange={(newValue) => {
                    setTime(newValue);
                  }}
                />
              </div>
            </div>
          </LocalizationProvider>
          <div className={`${c}__content-submit`}>
            <Button
              variant='contained'
              type='submit'
              onClick={submit}
              sx={{
                backgroundColor: '#ff1647',
                textTransform: 'none',
                fontWeight: 'bold',
                width: '100%',
                '&:hover': { backgroundColor: '#cc1138' },
              }}
            >
            Salveaza
            </Button>
          </div>
        </div>
      </div>
      {openQuantity && <QuantityModal isOpen={openQuantity} setIsOpen={setOpenQuantity} setProduct={setProduct} product={product} productsRef={productsRef} />}
    </ReactModal>
  )
}

export default CreateOrderModal