import React, { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DayStatsModal from '../day-stats-modal/DayStatsModal'
import './StatsCard.scss'

type Props = {
    stats: any
}

const c = 'stats-card'

const PriceStatsCard = (props: Props) => {
  const date = new Date();
  const currentMonth = date.toLocaleString('en-US', { month: 'long' }).toLowerCase();
  const {t} = useTranslation()
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('0');
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const months = Object.keys(props.stats.yearly?.[props.stats.yearly?.length - 1]?.months)
  const monthValues = props.stats.yearly?.[props.stats.yearly?.length - 1]?.months

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClickMonth = (month: string) => {
    setSelectedMonth(month)
    setIsOpen(true)
  }

  return (
    <div className={c}>
      <div className={`${c}__title`}>Profit</div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList variant="fullWidth" onChange={handleChange} sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#ff1647',
            },
            '& .MuiButtonBase-root.MuiTab-root': {
              color: 'black',
              transition: 'color 0.2s ease-in-out',
              '&:hover': {
                color: '#cc1138',
              },
              '&.Mui-selected': {
                color: '#ff1647',
              },
              '&.Mui-disabled': {
                color: 'lightgray',
              },
            },}}
          >
            <Tab label="Azi" value="0" />
            <Tab label="Pe Luna" value="1" />
            <Tab label="pe An" value="2" />
            <Tab label="Tot timpul" value="3" />
          </TabList>
        </Box>
        <TabPanel sx={{padding: '24px 0'}} value="0" className={`${c}__content`}>
          <div className={`${c}__content-stats`}>
            <div className={`${c}__content-stats-stat`}>
              <div className={`${c}__content-stats-stat-label`}>
                    Cost:
              </div>
              <div className={`${c}__content-stats-stat-value`}>
                {props.stats.today?.cost} lei
              </div>
            </div>
            <div className={`${c}__content-stats-stat`}>
              <div className={`${c}__content-stats-stat-label`}>
                    Vanzari:
              </div>
              <div className={`${c}__content-stats-stat-value`}>
                {props.stats.today?.sales} lei
              </div>
            </div>
            <div className={`${c}__content-stats-stat`}>
              <div className={`${c}__content-stats-stat-label`}>
                    Profit:
              </div>
              <div className={`${c}__content-stats-stat-value`}>
                {props.stats.today?.profit} lei
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="1" className={`${c}__content`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className='table-header-text'>Luna</TableCell>
                  <TableCell align="right" className='table-header-text'>Cecul Mediu</TableCell>
                  <TableCell align='right' className='table-header-text'>Nr. Comenzi</TableCell>
                  <TableCell align='right' className='table-header-text'>Livrare</TableCell>
                  <TableCell align='right' className='table-header-text'>Cost</TableCell>
                  <TableCell align="right" className='table-header-text'>Vanzari</TableCell>
                  <TableCell align="right" className='table-header-text'>Profit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {months.map((month, index) => (
                  <TableRow key={index} onClick={() => handleClickMonth(month)} className='table-body-row'>
                    <TableCell >
                      {t(month.toLocaleUpperCase())}
                    </TableCell>
                    <TableCell align="right">{(monthValues[month]?.sales / monthValues[month]?.orders_count || 0).toFixed(2)} lei</TableCell>
                    <TableCell align="right">{monthValues[month]?.orders_count}</TableCell>
                    <TableCell align="right">{monthValues[month]?.delivery_sales}</TableCell>
                    <TableCell align="right">{monthValues[month]?.cost.toFixed(2)} lei</TableCell>
                    <TableCell align="right">{monthValues[month]?.sales.toFixed(2)} lei</TableCell>
                    <TableCell align="right">{monthValues[month]?.profit.toFixed(2)} lei</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="2" className={`${c}__content`}>
          {props.stats.yearly?.map((stat: any, index: number) => (
            <React.Fragment key={index}>
              <div className={`${c}__content-title`}>{stat.year}</div>
              <div className={`${c}__content-stats`}>
                <div className={`${c}__content-stats-stat`}>
                  <div className={`${c}__content-stats-stat-label`}>
                    Cost:
                  </div>
                  <div className={`${c}__content-stats-stat-value`}>
                    {stat?.cost.toFixed(2)} lei
                  </div>
                </div>
                <div className={`${c}__content-stats-stat`}>
                  <div className={`${c}__content-stats-stat-label`}>
                    Vanzari:
                  </div>
                  <div className={`${c}__content-stats-stat-value`}>
                    {stat?.sales.toFixed(2)} lei
                  </div>
                </div>
                <div className={`${c}__content-stats-stat`}>
                  <div className={`${c}__content-stats-stat-label`}>
                    Profit:
                  </div>
                  <div className={`${c}__content-stats-stat-value`}>
                    {stat?.profit.toFixed(2)} lei
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </TabPanel>
        <TabPanel sx={{padding: '24px 0'}} value="3" className={`${c}__content`}>
          <div className={`${c}__content-stats`}>
            <div className={`${c}__content-stats-stat`}>
              <div className={`${c}__content-stats-stat-label`}>
                    Cost:
              </div>
              <div className={`${c}__content-stats-stat-value`}>
                {props.stats.allTime?.cost.toFixed(2)} lei
              </div>
            </div>
            <div className={`${c}__content-stats-stat`}>
              <div className={`${c}__content-stats-stat-label`}>
                    Vanzari:
              </div>
              <div className={`${c}__content-stats-stat-value`}>
                {props.stats.allTime?.sales.toFixed(2)} lei
              </div>
            </div>
            <div className={`${c}__content-stats-stat`}>
              <div className={`${c}__content-stats-stat-label`}>
                    Profit:
              </div>
              <div className={`${c}__content-stats-stat-value`}>
                {props.stats.allTime?.profit.toFixed(2)} lei
              </div>
            </div>
          </div>
        </TabPanel>
      </TabContext>
      <DayStatsModal isOpen={isOpen} setIsOpen={setIsOpen} data={monthValues[selectedMonth]?.days} selectedMonth={selectedMonth} />
    </div>
  )
}

export default PriceStatsCard