import { request } from '../request'

export const getIngredients = () => {
  return request.get('/ingredients')
}

export const createIngredient = (params: any) => {
  return request.post('/ingredients', { ...params })
}

export const deleteIngredient = (id: string) => {
  return request.delete(`/ingredients/${id}`)
}

export const editIngredient = (params: any, id: string) => {
  return request.put(`/ingredients/${id}`, { ...params })
}
